.article-blog-carte {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .image {
        width: 100%;
        height: 160px;
        object-fit: cover;
        transition: all 0.3s ease;
    }

    &:hover .image {
        transform: scale(1.05);
    }

    &:hover .title {
        color: $color-5;
    }

    .info {
        padding: 0% 8%;
        padding-bottom: 23%;
        height: 310px;

        .headerBlog {
            display: flex;
            justify-content: space-between;
            color: #b0aeae;
            z-index: 0;

            .category {
                text-transform: uppercase;
                font-size: 0.8rem;
                color: $color-1;
                margin-top: 7%;
            }

            .reading-time {
                display: flex;
                align-items: center;
                font-size: 0.8rem;
                color: #666;
                margin-top: 4%;

                img {
                    width: 40%;
                    margin-right: 8px;
                }

                .time {
                    margin-top: 0;
                }
            }
        }

        .title {
            font-size: 1.3rem;
            margin-top: 3%;
        }

        .keywords {
            margin-top: 4%;
            margin-bottom: 7%;

            .keyword {
                background-color: #eee;
                border-radius: 15px;
                padding: 5px 10px;
                font-size: 0.75rem;
            }
        }

        .excerpt {
            font-size: 1rem;

            .read-more {
                color: #007bff9b;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media (max-width: 915px) {
    .article-blog-carte {
        .info {
            .headerBlog {
                .category {
                    font-size: 0.7rem;
                }

                .reading-time {
                    font-size: 0.7rem;
                    margin: 3% 0%;

                    img {
                        width: 30%;
                        margin-right: 8px;
                    }

                    .time {
                        margin-top: 9%;
                    }
                }
            }

            .title {
                font-size: 1rem;
                margin-top: 3%;
            }

            .keywords {
                margin-top: 4%;
                margin-bottom: 7%;

                .keyword {
                    font-size: 0.6rem;
                }
            }

            .excerpt {
                font-size: .8rem;
            }
        }
    }
}

@media (max-width: 767px) {
    .article-blog-carte {
        width: 95%;
        max-width: 880px;
        margin: 0 auto;

        .image {
            height: 150px;
        }

        .info {
            padding-bottom: 13%;
            height: 300px;

            .title {
                font-size: 1.1rem;
                margin-top: 0%;
            }
        }
    }
}