.carrousel-blog {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: column;
    margin-top: 7%;
    min-height: 400px;

    .carrousel-blog__title {
        font-size: 24px;
        color: $color-1;
        text-transform: uppercase;
        font-family: $font-4;
        margin-bottom: 1%;
        letter-spacing: 0.05em;
    }

    .carrousel-blog__prev,
    .carrousel-blog__next {
        color: rgba(0, 0, 0, 0.574);
        font-size: 1em;
        width: 65px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        transition: background-color 0.3s;
        border: 1px solid rgba(0, 0, 0, 0.226);
        background-color: white;

        &:hover {
            background-color: rgba(59, 59, 59, 0.192);
        }
    }

    .carrousel-blog__prev {
        left: 5%;
    }

    .carrousel-blog__next {
        right: 5%;
    }

    .carrousel-blog__content {
        display: flex;
        align-items: center;
        width: 75%;
        overflow: hidden;
        margin: 3% auto;
        transition: opacity 0.5s ease-in-out;

        &::-webkit-scrollbar {
            display: none;
        }

        >* {
            margin: 0 2%;
            min-width: 0;
            flex: 1;
        }
    }

    .jps-metaview-wrapper {
        display: block;
        flex: 1;
    }
}

@media (max-width: 768px) {
    .carrousel-blog {
        .carrousel-blog__title {
            font-size: 20px;
            margin-bottom: 15px;
        }

        .carrousel-blog__prev,
        .carrousel-blog__next {
            display: none;
        }

        .carrousel-blog__content {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            padding-bottom: 10px;
            width: 102%;
            margin-left: -10px;

            &::-webkit-scrollbar {
                display: none;
            }

            >* {
                flex: 0 0 75vw;
                margin-left: 10px;
                margin-right: 0px;
            }
        }

        .jps-metaview-wrapper {
            width: 10%;
        }
    }
}