.section-descriptifs {
    padding: 0px 20px;
    margin: 0 3%;
    margin-bottom: 6%;
    font-size: 1.1em;

    .first-p {
        margin-bottom: 1.25%;
    }

    .first-h3 {
        font-size: 1.4em;
        margin-bottom: 1.5%;
        
        strong {
            font-family: $font-2;

        }
    }

    p {
        margin-bottom: 2%;
        line-height: 1.25;
        font-family: $font-1;
    }

    h3 {
        margin: 3px 0;
        font-family: $font-2;
        font-size: 1em;
        
        strong {
                font-family: $font-2;
        
        }
    }

    &>div {
        margin-top: 30px;
    }
}

@media (min-width: 1601px) {
    .section-descriptifs {
        padding: 0px 20px;
        margin: 0 4%;
        font-size: 1.1em;

        .first-h3 {
            font-size: 1.6em;
        }
    }
}

@media (max-width: 1600px) {
    .section-descriptifs {
        margin: 0 3.2%;

        .first-h3 {
            font-size: 1.5em;
        }
    }
}

@media (max-width: 1200px) {
    .section-descriptifs {
        padding: 0px 25px;
    }
}

@media (max-width: 1115px) {
    .section-descriptifs {
        margin: 0 2.5%;

        p {
            font-size: .9em;
        }
    }
}

@media (max-width: 915px) {
    .section-descriptifs {
        padding: 0px 15px;
        margin: 0 4%;

        .first-h3 {
            font-size: 1.2em;
        }
    }
}

@media (max-width: 767px) {
    .section-descriptifs {
        padding: 0px 10px;
        margin: 0 2%;
        margin-bottom: 4%;
        margin-top: 4%;
        font-size: 1.1em;

        .first-p {
            margin-bottom: 8%;
        }

        .first-h3 {
            font-size: 1.4em;
            margin-bottom: 4%;
        }

        p {
            margin-bottom: 4%;
            line-height: 1.4;
        }

        h3 {
            margin: 16px 0;
        }
    }
}