.nos-realisations.blur {
    filter: blur(5px);
    transition: filter 0.3s ease;
}

.popup-real {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1010;
    overflow: hidden;
}

.popup-content-real {
    background: white;
    padding: 1.5rem;
    border-radius: 10px;
    max-height: 90vh;
    max-width: 40%;
    min-width: 300px;
    overflow-y: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position: relative;

    .selected-card-text-section {
        padding: 5% 4%;

        h3 {
            font-size: 1.4em;
            color: $color-1;
            font-family: $font-2;
            margin-bottom: 6%;
        }

        .selected-card-location {
            margin: 0.5rem 0;
            font-size: 1.2em;
            font-family: $font-2;
            margin-bottom: 6%;
        }

        .selected-card-description {
            margin: 1rem 0;
            font-size: 1.2em;
            margin-bottom: 6%;
        }

        .selected-card-details {
            margin: 1rem 0;
            color: $color-1;
            font-size: 1.1em;
        }
    }
}

.popup-real img {
    width: auto;
    width: 100%;
    max-height: 80vh;
    object-fit: cover;
    margin-bottom: 1rem;
}

.popup-close-real {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2em;
    cursor: pointer;
    color: black;
}

.nos-realisations {
    position: relative;
    width: 100%;
    overflow: hidden;

    .nos-realisations__bandeau {
        position: relative;
        width: 100%;

        .bandeau-media__content {
            position: relative;
            width: 100%;
            height: 65vh;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                z-index: 1;
            }
        }

        .bandeau-media__media {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 0;
        }

        .nos-realisations__overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 2;
            width: 60%;
            padding: 0 20px;
            color: white;

            .nos-realisations__title {
                font-size: 1.2rem;
                margin: 0;
                text-transform: uppercase;
                margin-bottom: 3%;
                letter-spacing: 0.05em;
            }

            .nos-realisations__introduction {
                font-size: 2.3rem;
                margin: 0;
                margin-top: 10px;
                font-family: $font-4;
            }
        }
    }

    .filter-section {
        display: flex;
        justify-content: center;
        margin: 3rem 0;

        button {
            margin: 0 0.5rem;
            padding: 0.5rem .7rem;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            font-size: 1.2rem;
            transition: background-color 0.3s;
            color: black;
            background-color: white;
            transition: color 0.3s ease;
        
            &:hover {
                color: $color-5
            }

            &.active {
                color:$color-1;
                font-family: $font-2;
            }
        }
    }

    .card-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        justify-content: center;
        margin: 0% 4%;
    }

    .card {
        cursor: pointer;
        border: 1px solid #ddd;
        border-radius: 4%;
        overflow: hidden;
        width: 100%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
        &:hover .card-text-section h3 {
            color: $color-5;
        }

        img {
            width: 100%;
            height: auto;
        }

        .card-text-section {
            padding: 8% 6%;
        
            h3 {
                font-size: 1.2em;
                color: $color-1;
                font-family: $font-2;
                margin-bottom: 6%;
            }
        
            .card-location {
                margin: 0.5rem 0;
                color: black;
                font-size: 1em;
                font-family: $font-2;
                margin-bottom: 6%;
            }
        
            .card-description {
                margin: 1rem 0;
                color: black;
                font-size: 1em;
                margin-bottom: 6%;
            }
        
            .card-details {
                margin: 1rem 0;
                color: $color-1;
                font-size: .9em;
            }
        }
    }

    .filter-toggle-button {
        display: none;
        background-color: $color-1;
        color: white;
        padding: 0.5rem 1rem;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        font-size: 1rem;
        margin-bottom: 1rem;
        transition: background-color 0.3s ease;
    }

    .filter-menu {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}

@media (max-width: 768px) {
    .nos-realisations {
        
    .card {
        cursor: pointer;
        border: 1px solid #ddd;
        border-radius: 4%;
        overflow: hidden;
        width: 100%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    
        &:hover {
            transform: translateY(-0px);
            box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);
        }

        .card-text-section {
            padding: 10% 8%;
            h3 {
                font-size: 1.1em;
            }
            .card-location {
                font-size: 0.95em;
            }
            .card-description {
                font-size: 1em;
            }
            .card-details {
                font-size: 0.95em;
            }
        }
    }
    
    .nos-realisations__bandeau {

        .nos-realisations__overlay {
            width: 90%;
                .nos-realisations__title {
                    font-size: 1rem;
                }
        
                .nos-realisations__introduction {
                    font-size: 1.5rem;
                }
            }
    }

    .filter-section {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            border-top: 1px solid #ddd;
            background-color: white;
            position: relative;
            border-radius: 20px 20px 0 0;
            margin-top: -40px;
            z-index: 2;
            margin-left: 0%;
            margin-right: 0%;
            padding: 0% 6%;
    
            .filter-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                margin-top: 10%;
                padding: 2% 0%;
                position: relative;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;

                h4 {
                    margin: 0;
                    flex-grow: 1;
                    text-align: left;
                    font-family: $font-2;
                    color:  $color-1;
                }
    
                .chevron {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    color: gray;
                    font-size: 1rem;
                    padding: 0.3rem;
                    margin-left: auto;
                    cursor: pointer;
                    z-index: 1;
                }
    
                i {
                    pointer-events: none;
                }
            }
    
            .filter-dropdown {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-top: 0.5rem;
                width: 100%;
                padding: 0.5rem 0;
                background-color: #f9f9f9;
                border-top: 1px solid #ddd;
    
                button {
                    padding: 0.5rem 1rem;
                    text-align: left;
                    background: none;
                    border: none;
                    width: 100%;
                    cursor: pointer;
    
                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
            }        
        }

        .filter-toggle-button {
            display: block;
        }

        .filter-menu {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        .card-container {
            grid-template-columns: 1fr;
            gap: 1rem;
            margin: 0 5%;
        }

        .popup {
            display: none;
        }
    }
}