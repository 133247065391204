.jps-metaview {
    width: 100%;

    .jps-metaview__image {
        width: 100%;
        height: auto;
        display: block;
        object-fit: cover;
    }

    .jps-metaview__content {
        background-color: $color-1;
        color: white;
        padding: 2% 6%;
        padding-bottom: 10%;
        text-align: center;
        height: 100%;

        .jps-metaview__title {
            font-size: 1.5rem;
            margin-top: 6%;
        }

        .jps-metaview__subtitle {
            font-size: .8rem;
            margin: 9%;
        }

        .jps-metaview__button {
            background-color: $color-5;
            color: white;
            text-decoration: none;
            border-radius: 8px;
            font-size: .9rem;
            width: 100%;
            height: 48px;
            border: none;
            cursor: pointer;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            transition: background-color 0.3s ease, color 0.3s ease;

            &:hover {
                background-color: #0097a7;
            }
        }
    }
}

@media (min-width: 1601px) {
    .jps-metaview__image {
        max-height: 160px;
    }

    .jps-metaview__content {
        height: 315px;

        .jps-metaview__title {
            font-size: 1.8rem;
            margin-top: 3%;
        }

        .jps-metaview__subtitle {
            font-size: 1.2rem;
            margin: 5%;
        }

        .jps-metaview__button {
            font-size: 1rem;
            width: 93%;
        }
    }
}

@media (max-width: 1600px) {
    .jps-metaview__content {
        height: 320px;

        .jps-metaview__title {
            font-size: 1.75rem;
        }

        .jps-metaview__subtitle {
            font-size: .9rem;
        }

        .jps-metaview__button {
            font-size: 1rem;
        }
    }
}

@media (max-width: 1350px) {
    .jps-metaview__image {
        max-height: 200px;
    }

    .jps-metaview__content {
        height: 330px;

        .jps-metaview__title {
            font-size: 1.5rem;
            margin-top: 10%;
        }

        .jps-metaview__subtitle {
            font-size: .8rem;
            margin: 10% 0%;
        }
    }
}

@media (max-width: 1200px) {
    .jps-metaview__image {
        height: 160px;
    }

    .jps-metaview__content {
        height: 310px;

        .jps-metaview__title {
            font-size: 1.3rem;
            margin-top: 15%;
        }
    }
}

@media (max-width: 1080px) {
    .jps-metaview__image {
        height: 140px;
    }

    .jps-metaview__content {
        height: 330px;

        .jps-metaview__title {
            font-size: 1.3rem;
            margin-top: 15%;
        }
    }
}

@media (max-width: 990px) {
    .jps-metaview__image {
        height: 140px;
    }

    .jps-metaview__content {
        height: 335px;

        .jps-metaview__title {
            font-size: 1rem;
            margin-top: 25%;
        }

        .jps-metaview__subtitle {
            font-size: .7rem;
            margin: 30% 0%;
        }

        .jps-metaview__button {
            font-size: .7rem;
        }
    }
}

@media (max-width: 767px) {
    .jps-metaview {
        .jps-metaview__image {
            height: 150px;
        }
    
        .jps-metaview__content {
            height: 305px;

            .jps-metaview__title {
                font-size: 1.4rem;
                margin-top: 10%;
            }

            .jps-metaview__subtitle {
                font-size: .9rem;
                margin: 10% 0%;
            }

            .jps-metaview__button {
                font-size: .8rem;
            }
        }
    }
}