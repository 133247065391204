.mobile-menu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100vw;
    height: calc(130vh - 200px);
    background-color: #fff;
    z-index: 1000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    text-decoration: none;
    font-family: $font-1;
}

.mobile-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-decoration: none;

    .mobile-title {
        font-size: 1em;
        font-family: $font-1;
        color: $color-1;
        margin-bottom: 20px;
    }

    .mobile-section {
        margin-bottom: 10px;
        font-family: $font-1;

        .mobile-section-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: $font-2;

            a {
                text-decoration: none;

                h4 {
                    font-size: 16px;
                    margin: 0;
                    font-family: $font-2;
                    color: $color-1;

                    &:hover {
                        color: $color-5;
                    }
                }
            }

            .chevron {
                background: none;
                border: none;
                font-size: 1.5rem;
                color: $color-1;
                cursor: pointer;
                transition: transform 0.3s ease;

                i {
                    font-size: 1rem;
                    transition: transform 0.3s ease;
                }

                &.open {
                    transform: rotate(180deg);
                    color: #7777774c;
                }
            }
        }

        .submenu {
            margin-top: 10px;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            font-family: $font-1;

            a {
                color: $color-1;
                font-size: .9em;
                margin-bottom: 17px;
                text-decoration: none;
                font-family: $font-1;

                &:hover {
                    color: $color-5;
                }
            }
        }

        &.active {
            .mobile-section-header h4 {
                color: $color-5;
            }

            .mobile-section-header a {
                color: $color-5;
            }
        }
    }

    .mobile-link {
        font-size: 14px;
        color: $color-1;
        text-decoration: none;
        margin: 10px 0;

        &:hover {
            color: $color-5;
        }
    }
}

