.article-blog-page {

    .article-blog-page-container {
        margin: 0 auto;

        .article-blog-page__content {
            display: flex;
            gap: 0px;

            .article-blog-page__main {
                flex-grow: 1;
                width: calc(90% - 320px - 1px);

                .return__button {
                    background-color: $color-5;
                    color: white;
                    text-decoration: none;
                    border-radius: 8px;
                    font-size: .9rem;
                    width: 30%;
                    height: 48px;
                    border: none;
                    cursor: pointer;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: auto;
                    margin-bottom: 60px;
                    margin-top: 5%;
                    transition: background-color 0.3s ease, color 0.3s ease;
            
                    &:hover {
                        text-decoration: none;
                        background-color: #0097a7;
                    }
                }
            }

            .article-blog-page__aside {
                width: 320px;
                position: relative;
                margin-top: 610px;
                margin-right: 101px;

                .jps-metaview-container {
                    position: relative;
                    max-width: 320px;

                    &.jps-metaview-fixed {
                        position: fixed;
                        top: 200px;
                        width: 100%;
                        max-width: 320px;
                        z-index: 999;
                    }

                    &.jps-metaview-stopped {
                        position: absolute;
                        bottom: 0;
                        top: auto;
                        width: 100%;
                        max-width: 320px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .article-blog-page {
        .article-blog-page-container {
            .article-blog-page__content {
                display: flex;
                flex-direction: column; 
                gap: 0px;

                .article-blog-page__main {
                    width: 100%; 
                    margin-right: 0;

                    .return__button {
                        background-color: $color-5;
                        color: white;
                        text-decoration: none;
                        border-radius: 8px;
                        font-size: .9rem;
                        width: 80%;
                        height: 48px;
                        border: none;
                        cursor: pointer;
                        text-transform: uppercase;
                        letter-spacing: 0.05em;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: auto;
                        margin-bottom: 30px;
                        transition: background-color 0.3s ease, color 0.3s ease;

                        &:hover {
                            text-decoration: none;
                            background-color: #0097a7;
                        }
                    }
                }

                .article-blog-page__aside {
                    display: none;
                }
            }
        }
    }
}