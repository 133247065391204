.aside {
    width: 30%;
    background-color: $color-1;
    color: white;
    padding: 1rem;
    height: 100vh;

    .aside-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            width: 50%;
            height: auto;
        }

        .logo-mobile {
            display: none;
        }

        .back-button {
            display: flex;
            align-items: center;
            background: none;
            border: none;
            color: rgba(255, 255, 255, 0.476);
            cursor: pointer;
            margin-right: 1rem;

            .arrow {
                font-size: 1rem;
                margin-right: 0.5rem;
            }

            .return {
                font-size: .7rem;
            }
        }
    }

    .aside-content {
        margin-top: 35%;
        padding: 10%;

        h2 {
            font-size: 1.4rem;
            width: 63%;
        }

        p {
            margin-top: 7%;
            font-family: $font-5;
            font-size: 0.9rem;
        }

        .traits {
            margin-top: 20%;

            .trait {
                height: 2px;
                background-color: white;
                margin: 1rem 0;
                width: 100%;
            }
        }
    }
}

.aside-footer {
    margin-left: 5%;
    margin-top: 5%;
    padding: 1rem;
}

.home-button {
    display: inline-block;
    padding: 10px 40px;
    font-size: 16px;
    color: #fff;
    background-color: $color-5;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.home-button:hover {
    background-color: #0097a7;
}

@media (max-width: 767px) {
    .aside {
        width: 100%;
        background-color: $color-1;
        color: white;
        height: 40vh;
        padding: 0;

        .aside-header {
            background-color: white;
            width: 100%;
            height: 25%;

            .logo {
                width: 60%;
                height: auto;
                margin-left: 3%;
                padding-left: 0;
                background-color: white;
            }

            .logo-desktop {
                display: none;
            }

            .logo-mobile {
                display: block;
            }

            .back-button {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-1;
                border: none;
                color: white;
                width: 82px;
                height: 35px;
                border-radius: 50%;
                margin-right: 1rem;
                cursor: pointer;

                .arrow {
                    text-align: center;
                    font-size: 1.3rem;
                    transform: rotate(-90deg);
                    display: inline-block;
                    color: white;
                }

                .return {
                    display: none;
                }
            }
        }

        .aside-content {
            margin-top: 5%;
            padding: 3% 7%;
            text-align: left;
            height: 27%;

            h2 {
                font-size: 1.4rem;
                width: 100%;
            }

            p {
                margin-top: 1%;
                font-family: $font-5;
                font-size: 0.9rem;
            }

            .traits {
                margin-top: 2%;

                .trait {
                    height: 2px;
                    background-color: white;
                    margin: 1rem 0;
                    width: 100%;
                }
            }
        }
    }

    .aside-footer {
        margin-left: 2.5%;
        margin-top: 8%;
        padding: 1rem;
    }

    .home-button {
        display: inline-block;
        padding: 7px 24px;
        font-size: 12px;
        color: #fff;
        background-color: $color-5;
        text-decoration: none;
        border-radius: 5px;
        transition: background-color 0.3s ease;
        margin-top: 2.5%;
    }

    .home-button:hover {
        background-color: #0097a7;
    }
}