.bandeau-media-animation {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  padding: 4%;

  &.row-reverse {
    flex-direction: row-reverse;
  }

  &__images {
    flex: 1;

    h1 {
      display: none;
    }

    .bandeau-media-animation__iframe-container {
      position: relative;
      width: 100%;
      height: 100%;

      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }

      .bandeau-media-animation__instruction {
        position: absolute;
        left: -70px;
        font-style: italic;
        text-align: center;
        width: 100%;
        z-index: 10;
      }
    }

    .bandeau-media-animation__image-container {
      margin: auto;

      .bandeau-media-animation__instruction {
        font-style: italic;
        text-align: center;
      }
    }
  }

  &__text {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: auto auto;
    width: 100%;
    height: 100%;

    h2 {
      font-size: 1.3em;
      color: $color-3;
      margin-bottom: 2%;
      font-family: $font-4;
    }

    p {
      font-size: 1em;
      color: #000;
      margin-bottom: 1.9%;
      line-height: 130%;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-bottom: 2%;

      li {
        font-size: 1em;
        line-height: 1.4em;
        display: flex;
        align-items: center;
        color: $color-1;

        .fas {
          margin-right: 2%;
        }

        .icon-check {
          color: $color-1;
        }
      }
    }

    button {
      padding: 2% 2%;
      height: 55px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-size: 0.8em;
      background-color: $color-1;
      color: #fff;
      text-transform: uppercase;
      margin-top: 1%;
      width: 37%;
      letter-spacing: 0.05em;
      transition: background-color 0.3s ease, color 0.3s ease;

      &:hover {
        text-decoration: none;
        background-color: #2a9ba0;
      }
    }
  }
}

@media (min-width: 1820px) {
  .bandeau-media-animation {
    &__images {
      flex: 1;

      h1 {
        display: none;
      }

      .bandeau-media-animation__iframe-container {
        iframe {
          width: 900px;
          height: 800px;
        }

        .bandeau-media-animation__instruction {
          left: 0px;
        }
      }

      .bandeau-media-animation__image-container {
        height: 470px;
      }
    }

    &__text {
      h2 {
        font-size: 1.5em;
      }

      p {
        font-size: 1.1em;
        line-height: 130%;
      }

      ul {
        margin-bottom: 2%;

        li {
          font-size: 1.1em;
          line-height: 1.4em;

          .fas {
            margin-right: 2%;
          }
        }
      }

      button {
        padding: 2% 2%;
        height: 55px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 0.8em;
        background-color: $color-1;
        color: #fff;
        text-transform: uppercase;
        margin-top: 1%;
        width: 30%;
        letter-spacing: 0.05em;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          text-decoration: none;
          background-color: #2a9ba0;
        }
      }
    }
  }
}

@media (min-width: 1601px) {
  .bandeau-media-animation {
    &__images {
      flex: 1;

      h1 {
        display: none;
      }

      .bandeau-media-animation__iframe-container {
        iframe {
          width: 800px;
          height: 500px;
        }

        .bandeau-media-animation__instruction {
          left: 0px;
        }
      }

      .bandeau-media-animation__image-container {
        height: 470px;
      }
    }

    &__text {
      h2 {
        font-size: 1.5em;
      }

      p {
        font-size: 1.1em;
        line-height: 130%;
      }

      ul {
        margin-bottom: 2%;

        li {
          font-size: 1.1em;
          line-height: 1.4em;

          .fas {
            margin-right: 2%;
          }
        }
      }

      button {
        padding: 2% 2%;
        height: 50px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 0.8em;
        background-color: $color-1;
        color: #fff;
        text-transform: uppercase;
        margin-top: 1%;
        width: 33%;
        letter-spacing: 0.05em;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          text-decoration: none;
          background-color: #2a9ba0;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .bandeau-media-animation {
    gap: 20px;

    &__images {
      flex: 1;

      .bandeau-media-animation__iframe-container {
        position: relative;
        width: 100%;

        iframe {
          width: 700px;
          height: 440px;
          border: none;
        }

        .bandeau-media-animation__instruction {
          left: 0px;
        }
      }

      .bandeau-media-animation__image-container {
        height: 450px;

        img {
          width: 50%;
          margin-bottom: 1%;
        }

        .bandeau-media-animation__instruction {
          margin-top: 0%;
        }
      }
    }

    &__text {
      h2 {
        font-size: 1.4em;
        margin-bottom: 2%;
      }

      p {
        font-size: 1em;
        margin-bottom: 1.9%;
        line-height: 130%;
      }

      ul {
        margin-bottom: 2%;

        li {
          font-size: 1em;
          line-height: 1.3em;

          .fas {
            margin-right: 2%;
          }
        }
      }

      button {
        padding: 2% 2%;
        height: 50px;
        font-size: 0.8em;
        background-color: $color-1;
        color: #fff;
        text-transform: uppercase;
        margin-top: 1%;
        width: 30%;
        letter-spacing: 0.05em;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          text-decoration: none;
          background-color: #2a9ba0;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .bandeau-media-animation {
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 4%;

    &.row-reverse {
      flex-direction: row-reverse;
    }

    &__images {
      flex: 1;

      h1 {
        display: none;
      }

      .bandeau-media-animation__iframe-container {
        position: relative;
        width: 100%;

        iframe {
          width: 680px;
          height: 430px;
          border: none;
        }

        .bandeau-media-animation__instruction {
          left: 0px;
        }
      }

      .bandeau-media-animation__image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 380px;

        img {
          width: 50%;
          height: auto;
          object-fit: cover;
        }

        .bandeau-media-animation__instruction {
          font-style: italic;
          text-align: center;
          margin-top: 0%;
        }
      }
    }

    &__text {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: auto auto;
      width: 100%;
      height: 100%;

      h2 {
        font-size: 1.3em;
        color: $color-3;
        margin-bottom: 2%;
        font-family: $font-4;
      }

      p {
        font-size: 0.95em;
        color: #000;
        margin-bottom: 1.9%;
        line-height: 130%;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-bottom: 2%;

        li {
          font-size: 0.95em;
          line-height: 1.3em;
          display: flex;
          align-items: center;
          color: $color-1;

          .fas {
            margin-right: 2%;
          }

          .icon-check {
            color: $color-1;
          }
        }
      }

      button {
        padding: 2% 2%;
        height: 50px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 0.8em;
        background-color: $color-1;
        color: #fff;
        text-transform: uppercase;
        margin-top: 1%;
        width: 30%;
        letter-spacing: 0.05em;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          text-decoration: none;
          background-color: #2a9ba0;
        }
      }
    }
  }
}

@media (max-width: 1355px) {
  .bandeau-media-animation {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 4%;

    &.row-reverse {
      flex-direction: row-reverse;
    }

    &__images {
      flex: 1;

      h1 {
        display: none;
      }

      .bandeau-media-animation__iframe-container {
        position: relative;
        width: 100%;

        iframe {
          width: 600px;
          height: 375px;
          border: none;
        }

        .bandeau-media-animation__instruction {
          left: 0px;
        }
      }

      .bandeau-media-animation__image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 380px;

        img {
          width: 50%;
          height: auto;
          object-fit: cover;
        }

        .bandeau-media-animation__instruction {
          font-style: italic;
          text-align: center;
          margin-top: 0%;
        }
      }
    }

    &__text {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: auto auto;
      width: 100%;
      height: 100%;

      h2 {
        font-size: 1.3em;
        color: $color-3;
        margin-bottom: 2%;
        font-family: $font-4;
      }

      p {
        font-size: 0.95em;
        color: #000;
        margin-bottom: 1.9%;
        line-height: 130%;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-bottom: 2%;

        li {
          font-size: 0.95em;
          line-height: 1.3em;
          display: flex;
          align-items: center;
          color: $color-1;

          .fas {
            margin-right: 2%;
          }

          .icon-check {
            color: $color-1;
          }
        }
      }

      button {
        padding: 2% 2%;
        height: 50px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 0.8em;
        background-color: $color-1;
        color: #fff;
        text-transform: uppercase;
        margin-top: 1%;
        width: 35%;
        letter-spacing: 0.05em;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          text-decoration: none;
          background-color: #2a9ba0;
        }
      }
    }
  }
}

@media (max-width: 1155px) {
  .bandeau-media-animation {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4%;

    &.row-reverse {
      flex-direction: row-reverse;
    }

    &__images {
      flex: 1;

      h1 {
        display: none;
      }

      .bandeau-media-animation__iframe-container {
        position: relative;
        width: 100%;

        iframe {
          width: 500px;
          height: 310px;
          border: none;
        }

        .bandeau-media-animation__instruction {
          left: 0px;
          font-size: 0.9em;
        }
      }

      .bandeau-media-animation__image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 300px;

        img {
          width: 50%;
          height: auto;
          object-fit: cover;
        }

        .bandeau-media-animation__instruction {
          font-style: italic;
          text-align: center;
          margin-top: 0%;
          font-size: 0.9em;
        }
      }
    }

    &__text {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: auto auto;
      width: 100%;
      height: 100%;
      margin-left: 1%;

      h2 {
        font-size: 1.1em;
        color: $color-3;
        margin-bottom: 2%;
        font-family: $font-4;
      }

      p {
        font-size: 0.85em;
        color: black;
        margin-bottom: 1.9%;
        line-height: 110%;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-bottom: 2%;

        li {
          font-size: 0.85em;
          line-height: 1.1em;
          display: flex;
          align-items: center;
          color: $color-1;

          .fas {
            margin-right: 2%;
          }

          .icon-check {
            color: $color-1;
          }
        }
      }

      button {
        padding: 2% 2%;
        height: 47px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 0.8em;
        background-color: $color-1;
        color: #fff;
        text-transform: uppercase;
        margin-top: 1%;
        width: 40%;
        letter-spacing: 0.05em;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          text-decoration: none;
          background-color: #2a9ba0;
        }
      }
    }
  }
}

@media (max-width: 915px) {
  .bandeau-media-animation {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 4%;

    &.row-reverse {
      flex-direction: row-reverse;
    }

    &__images {
      flex: 1;

      h1 {
        display: none;
      }

      .bandeau-media-animation__iframe-container {
        position: relative;
        width: 100%;

        iframe {
          width: 440px;
          height: 275px;
          border: none;
        }

        .bandeau-media-animation__instruction {
          left: 0px;
          font-size: 0.8em;
        }
      }

      .bandeau-media-animation__image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 260px;

        img {
          width: 50%;
          height: auto;
          object-fit: cover;
        }

        .bandeau-media-animation__instruction {
          font-style: italic;
          text-align: center;
          margin-top: 0%;
        }
      }
    }

    &__text {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: auto auto;
      width: 100%;
      height: 100%;

      h2 {
        font-size: 0.9em;
        color: $color-3;
        margin-bottom: 2%;
        font-family: $font-4;
      }

      p {
        font-size: 0.7em;
        color: #000;
        margin-bottom: 1.9%;
        line-height: 110%;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-bottom: 2%;

        li {
          font-size: 0.7em;
          line-height: 1.1em;
          display: flex;
          align-items: center;
          color: $color-1;

          .fas {
            margin-right: 2%;
          }

          .icon-check {
            color: $color-1;
          }
        }
      }

      button {
        padding: 2% 2%;
        height: 40px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 0.65em;
        background-color: $color-1;
        color: #fff;
        text-transform: uppercase;
        margin-top: 1%;
        width: 40%;
        letter-spacing: 0.05em;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          text-decoration: none;
          background-color: #2a9ba0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .bandeau-media-animation {
    background-color: #f8f9f9;
    flex-direction: column;
    align-items: center;
    margin: 0%;
    padding: 6%;
    padding-top: 1%;
    position: relative;
    border-radius: 20px 20px 0 0;
    margin-top: -20px;
    z-index: 2;

    &__images {
      width: 100%;
      position: relative;

      h1 {
        display: block;
        font-size: 1.3em;
        margin: 10% 4%;
        font-family: $font-2;
        text-align: left;
      }

      .bandeau-media-animation__iframe-container {
        border-radius: 20px 20px 0 0;
        width: 100%;
        height: 230px;
        display: flex;
        justify-content: center;
        padding: 7% 0%;

        iframe {
          width: 315px;
          height: 200px;
          border: none;
        }

        .bandeau-media-animation__instruction {
          top: 232px;
          left: 0px;
          font-size: 0.8em;
          padding-bottom: 6%;
        }
      }
    }

    &__text {
      padding: 3%;
      margin: 0% 10%;
      margin-bottom: 10%;
      text-align: left;
      border-radius: 0px 0px 20px 20px;

      h2 {
        margin-bottom: 6%;
        font-size: 1.4em;
        line-height: 1.1em;
      }

      p {
        font-size: 1.1em;
        margin-bottom: 10%;
      }

      p:last-of-type {
        display: none;
      }

      ul {
        margin-bottom: 10%;

        li {
          line-height: 1.4em;
          font-size: 1em;

          .fas {
            margin-right: 4%;
            font-size: 0.7em;
          }
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        text-decoration: none;

        button {
          width: 250px;
          height: 54px;
          padding: 2% 3%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8em;
          text-align: center;
          background-color: $color-1;
          color: white;
          border: none;
          cursor: pointer;
          transition: font-size 0.3s ease, background-color 0.3s ease;
        }
      }

      button:hover {
        background-color: $color-5;
      }
    }
  }

  .bandeau-media-animation.id-3,
  .bandeau-media-animation.id-2 {
    flex-direction: column;
    border-radius: 0px 0px 0px 0px;
    margin: 0%;

    &__images {
      order: -1;
      width: 100%;

      .bandeau-media-animation__iframe-container {
        border-radius: 20px 20px 0 0;
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        padding: 7% 7%;

        iframe {
          width: 320px;
          height: 200px;
          border: none;
        }

        .bandeau-media-animation__instruction {
          top: 207px;
          left: 0px;
          font-size: 0.8em;
          padding-bottom: 6%;
        }
      }
      .bandeau-media-animation__image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 200px;
        background-color: #2a9ba0;

        img {
          width: 50%;
          height: auto;
          object-fit: cover;
        }
        .bandeau-media-animation__instruction {
          font-style: italic;
          text-align: center;
          margin-top: 0%;
        }
      }
    }
    &__text {
      order: 1;
      width: 100%;
      text-align: left;
      padding: 0% 3%;

      h2 {
        margin-bottom: 6%;
        font-size: 1.6em;
      }
      p {
        font-size: 1.1em;
        margin-bottom: 10%;
      }
      ul {
        margin-bottom: 10%;
        li {
          line-height: 1.5em;
          .fas {
            margin-right: 5%;
            font-size: 0.7em;
          }
        }
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 73%;
        margin: auto;
        text-decoration: none;

        button {
          width: 250;
          height: 54px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1em;
          text-align: center;
          background-color: #007bff;
          color: white;
          border: none;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
      }

      button:hover {
        background-color: #0056b3;
      }
    }
  }
}
