.bandeau-descriptif {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3% 3.5%;

  &.reverse {
    flex-direction: row-reverse;
  }

  .image-section {
    margin-right: 1.9em;
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-right: -5%;

    .titre {
      font-size: 1.4em;
      margin-bottom: 8px;
      font-family: $font-4;
      color: $color-1;
    }

    p {
      font-size: 1em;
      color: black;
      margin-top: 0.6em;
      line-height: 1.2em;
      padding-right: 3%;
      width: 95%;
    }

    .liste {
      list-style-type: none;
      padding: 0;
      color: $color-1;
      font-size: 1.1em;

      li {
        line-height: 0.95em;
        display: flex;
        align-items: center;

        .fas {
          margin-right: 0.5rem;
        }
      }
    }

    .btn-primary {
      width: 40%;
      height: 52px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 0.7em;
      font-family: $font-3;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease;
      letter-spacing: 0.05em;
      margin-top: 5%;
      padding: 0% 4%;
      background-color: $color-1;
      color: white;
      margin-right: 4%;

      &:hover {
        text-decoration: none;
        background-color: #2a9ba0;
      }
    }
  }
}

@media (max-width: 768px) {
  .bandeau-descriptif {
    flex-direction: column;
    padding: 5% 3%;
    position: relative;
    border-radius: 20px 20px 0 0;
    margin-top: -20px;
    z-index: 2;
    background-color: white;

    &.reverse {
      flex-direction: column;
    }

    .image-section {
      width: 100%;
      margin-right: 0;
      position: relative;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .text-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 97%;
      margin: 7% 3%;
      padding-right: 7%;
      padding-left: 3%;

      .titre {
        font-size: 1.3em;
        margin-bottom: 10px;
        font-family: $font-2;
      }

      p {
        font-size: 1em;
        color: #555;
        margin-top: 1em;
        line-height: 1.4em;
        padding-right: 0;
        width: 100%;
      }

      .liste {
        list-style-type: none;
        padding: 0;
        color: $color-3;
        font-size: 1em;

        li {
          display: flex;
          align-items: center;
          line-height: 1.4em;

          .fas {
            margin-right: 0.7rem;
          }
        }
      }

      .btn-primary {
        width: 60%;
        height: 50px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 0.8em;
        font-family: $font-3;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s ease;
        letter-spacing: 0.05em;
        margin-top: 15px;
        text-decoration: none;
        background-color: $color-1;
        color: #fff;
        margin: auto;
        margin-top: 10%;

        &:hover {
          text-decoration: none;
          font-weight: bold;
          font-size: 0.85em;
          background-color: #2a9ba0;
        }
      }
    }
  }
}
