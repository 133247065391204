.domaines {
    padding: 3%;

    &.template-section {
        padding: 4% 2%;
    }

    .domaines__title {
        text-align: center;
        color: $color-1;
        margin-top: 40px;
        margin-bottom: 40px;
        text-transform: uppercase;
        font-size: 1.5em;
        letter-spacing: 0.05em;
        font-family: $font-4;

        &.template-section__title {
            margin-bottom: 40px;
            text-transform: uppercase;
            font-size: 2em;
        }
    }

    .domaines__grid {
        display: flex;
        flex-wrap: wrap;
    }

    .domaines__item {
        display: flex;
        align-items: flex-end;
        width: calc(25% - 1px);
        border-bottom: 1px solid #e0e0e0;
        box-sizing: border-box;
        padding: 1%;
        border-right: 1px solid #e0e0e0;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:nth-child(4),
        &:nth-child(8) {
            border-right: none;
        }

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
            border-bottom: none;
        }

        &.template-section__item {
            padding-right: 1%;
        }

        .domaines__image {
            flex-shrink: 0;
            width: 30%;
            height: 100px;
            overflow: hidden;
            display: flex;
            align-items: end;
            justify-content: end;
            margin-right: 8%;
            padding-bottom: 5%;

            img {
                max-width: 100%;
                max-height: 60px;
                height: auto;
                object-fit: cover;
                object-position: center;
            }
        }

        .domaines__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            height: 90%;

            .domaines__item-title {
                font-size: .9em;
                color: $color-3;
                margin-bottom: 10px;
                font-family: $font-2;
            }

            .domaines__list {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    font-size: .9em;
                    color: #333;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                    line-height: .9em;

                    .domaines__list-icon {
                        margin-right: 5px;
                        color: #000;
                    }
                }
            }
        }

        &.hide-border {
            border-bottom: none;
        }
    }
}

@media (min-width: 1601px) {
    .domaines {
        padding: 3%;

        &.template-section {
            padding: 4% 2%;
        }

        .domaines__title {
            margin-bottom: 40px;
            text-transform: uppercase;
            font-size: 30px;
        }

        .domaines__grid {
            display: flex;
            flex-wrap: wrap;
        }

        .domaines__item {
            display: flex;
            align-items: flex-end;
            width: calc(25% - 1px);
            border-bottom: 1px solid #e0e0e0;
            box-sizing: border-box;
            padding: 1%;
        }

        .domaines__image {
            flex-shrink: 0;
            width: 30%;
            height: 100px;
            overflow: hidden;
            display: flex;
            align-items: end;
            justify-content: end;
            margin-right: 8%;
            padding-bottom: 5%;

            img {
                max-width: 100%;
                max-height: 80px;
                height: auto;
                object-fit: cover;
                object-position: center;
            }
        }

        .domaines__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            height: 90%;

            .domaines__item-title {
                font-size: 1.1em;
                color: $color-3;
                margin-bottom: 10px;
                font-family: $font-2;
            }

            .domaines__list {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    font-size: 1em;
                    color: #333;
                    line-height: 1.1em;
                }
            }
        }

        &.hide-border {
            border-bottom: none;
        }
    }
}

@media (max-width: 1115px) {
    .domaines {
            padding: 3%;
    
            &.template-section {
                padding: 4% 2%;            
            }
    
            .domaines__title {
                text-align: center;
                color: $color-1;
                margin-bottom: 30px;
                text-transform: uppercase;
                font-size: 1.2em;
                letter-spacing: 0.05em;
                font-family: $font-4;
    
                &.template-section__title {
                    text-align: center;
                    color: $color-1;
                    margin-bottom: 40px;
                    text-transform: uppercase;
                    font-size: 1.4em;
                    letter-spacing: 0.05em;
                }
            }
    
            .domaines__grid {
                display: flex;
                flex-wrap: wrap;
            }
    
            .domaines__item {
                display: flex;
                align-items: flex-end;
                width: calc(25% - 1px);
                border-bottom: 1px solid #e0e0e0;
                box-sizing: border-box;
                padding: .5%;
                padding-bottom: 2%;
                padding-left: 1%;                
                border-right: 1px solid #e0e0e0;
    
                &.template-section__item {
                    padding-right: 1%;
                }
    
                .domaines__image {
                    flex-shrink: 0;
                    width: 24%;
                    height: 100px;
                    overflow: hidden;
                    display: flex;
                    align-items: end;
                    justify-content: end;
                    margin-right: 8%;
                    padding-bottom: 7%;
    
                    &.template-section__image {
                        flex-shrink: 0;
                        margin: 0px 14px;
                        width: 22%;
                        height: 100px;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
    
                    img {
                        max-width: 100%;
                        max-height: 80px;
                        height: auto;
                        object-fit: cover;
                        object-position: center;
                    }
                }
    
                .domaines__content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    height: 90%;
    
                    .domaines__item-title {
                        font-size: .8em;
                        color: $color-3;
                        margin-bottom: 10px;
                        font-family: $font-2;
    
                        &.template-section__item-title {
                            font-size: .8em;
                            color: $color-3;
                            margin-bottom: 10px;
                            font-family: $font-2;
                        }
                    }
    
                    .domaines__list {
                        list-style: none;
                        padding: 0;
                        margin: 0;
    
                        li {
                            font-size: .7em;
                            color: #333;
                            margin-bottom: 5px;
                            display: flex;
                            align-items: center;
                            line-height: .9em;
    
                            &.template-section__list-item {
                                color: #333;
                                font-size: .7em;
                                line-height: 1.1em;
                            }
    
                            .domaines__list-icon {
                                margin-right: 5px;
                                color: #000;
    
                                &.template-section__list-icon {}
                            }
                        }
                    }
                }
    
                &.hide-border {
                    border-bottom: none;
                }
            }
        }
}

@media (max-width: 915px) {
    .domaines {
            padding: 3%;
    
            &.template-section {
                padding: 4% 2%;
            }
    
            .domaines__title {
                text-align: center;
                color: $color-1;
                margin-bottom: 40px;
                text-transform: uppercase;
                font-size: 1.1em;                
                letter-spacing: 0.05em;
                font-family: $font-4;
    
                &.template-section__title {
                    text-align: center;
                    color: $color-1;
                    margin-bottom: 40px;
                    text-transform: uppercase;
                    font-size: 1.1em;
                    letter-spacing: 0.05em;
                    font-family: $font-4;
                }
            }
    
            .domaines__grid {
                display: flex;
                flex-wrap: wrap;
            }
    
            .domaines__item {
                display: flex;
                align-items: flex-end;
                width: calc(25% - 1px);
                border-bottom: 1px solid #e0e0e0;
                box-sizing: border-box;
                padding: .5%;
                padding-bottom: 2%;
                padding-left: 1%;
                border-right: 1px solid #e0e0e0;
    
                &.template-section__item {
                    display: flex;
                    align-items: flex-end;
                    width: calc(25% - 1px);
                    border-bottom: 1px solid #e0e0e0;
                    box-sizing: border-box;
                    padding: .5%;
                    padding-bottom: 2%;
                    padding-left: 1%;
                }
    
                .domaines__image {
                    flex-shrink: 0;
                    width: 24%;
                    height: 100px;
                    overflow: hidden;
                    display: flex;
                    align-items: flex-end;
                    justify-content: end;
                    margin-right: 8%;
                    padding-bottom: 0%;
    
                    &.template-section__image {
                        flex-shrink: 0;
                        margin: 0px 15px;
                        width: 22%;
                        height: 100px;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
    
                    img {
                        max-width: 100%;
                        max-height: 80px;
                        height: auto;
                        object-fit: cover;
                        object-position: center;
                    }
                }
    
                .domaines__content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: 68%;
                    height: 90%;
    
                    .domaines__item-title {
                        font-size: .6em;
                        color: $color-3;
                        margin-bottom: 10px;
                        font-family: $font-2;
    
                        &.template-section__item-title {
                            font-size: .9em;
                            color: $color-3;
                            margin-bottom: 10px;
                            font-family: $font-2;
                        }
                    }
    
                    .domaines__list {
                        list-style: none;
                        padding: 0;
                        margin: 0;
    
                        li {
                            font-size: .65em;
                            color: #333;
                            margin-bottom: 5px;
                            display: flex;
                            align-items: center;
                            line-height: .9em;
    
                            &.template-section__list-item {
                                font-size: .85em;
                                color: #333;
                                margin-bottom: 5px;
                                display: flex;
                                align-items: center;
                                line-height: 1em;
                            }
    
                            .domaines__list-icon {
                                margin-right: 5px;
                                color: #000;
    
                                &.template-section__list-icon {}
                            }
                        }
                    }
                }
    
                &.hide-border {
                    border-bottom: none;
                }
            }
        }
}

@media (max-width: 767px) {
    .domaines {
        padding: 3%;
        width: 100%;
        box-sizing: border-box;

        &.template-section {
            padding-top: 0%;
            width: 100%;
            box-sizing: border-box;
        }

        .domaines__title {
            text-align: center;
            color: $color-1;
            margin-bottom: 40px;
            text-transform: uppercase;
            font-size: 24px;
            letter-spacing: 0.05em;
            font-family: $font-4;

            &.template-section__title {
                text-align: center;
                color: $color-1;
                margin-bottom: 40px;
                text-transform: uppercase;
                font-size: 24px;
                letter-spacing: 0.05em;
                font-family: $font-4;
            }
        }

        .domaines__grid {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0 auto;
        }

        .domaines__item {
            display: flex;
            align-items: flex-end;
            width: calc(50% - 1%);
            margin-bottom: 0px;
            border-bottom: 1px solid #e0e0e0;
            padding-bottom: 8%;
            box-sizing: border-box;
            border-right: none;

            &:nth-child(odd) {
                margin-right: 2%;
            }

            &:nth-child(even) {
                margin-right: 0;
            }

            &:nth-child(7),
            &:nth-child(8) {
                border-top: 1px solid #e0e0e0;            
            }

            &.template-section__item {
                display: flex;
                align-items: flex-end;
                width: calc(100% - 1.3%);
                margin-bottom: 0px;
                border-bottom: none;
                padding-bottom: 0%;
                box-sizing: border-box;
                border-right: none;
                margin-top: 4%;
                padding-right: 5%;
            }

            .domaines__image {
                flex-shrink: 0;
                width: 27%;
                height: 100px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;

                img {
                    max-width: 100%;
                    max-height: 60px;
                    height: auto;
                    object-fit: cover;
                    object-position: center;
                }

                &.template-section__image {
                    flex-shrink: 0;
                    height: 100px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 25px;
                    margin-right: 8%;
                    padding-bottom: 0%;

                    img {
                        max-width: 100%;
                        max-height: 60px;
                        height: auto;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            .domaines__content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 78%;

                .domaines__item-title {
                    font-size: .8em;
                    color: $color-3;
                    margin-bottom: 8px;
                    font-family: $font-2;
                }

                .domaines__list {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        font-size: 0.85em;
                        color: #333;
                        margin-bottom: 1px;
                        display: flex;
                        align-items: center;
                        line-height: 1.1em;

                        .domaines__list-icon {
                            margin-right: 5px;
                            color: #000;
                        }
                    }
                }

                &.template-section__content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 78%;

                    .domaines__item-title {
                        font-size: .8em;
                        color: $color-3;
                        margin-bottom: 8px;
                        font-family: $font-2;
                    }

                    .domaines__list {
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li {
                            font-size: 0.6em;
                            color: #333;
                            margin-bottom: 1px;
                            display: flex;
                            align-items: center;
                            line-height: 1.1em;

                            &.template-section__list-item {
                                font-size: .8em;
                                color: $color-1;
                                margin-bottom: 0px;
                                display: flex;
                                align-items: center;
                                line-height: 1em;
                            }

                            .domaines__list-icon {
                                margin-right: 5px;
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
    }
}