.jps-films-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 4.5%;

    .content {
        flex: 1;
        padding-right: 7%;
        text-align: left;

        h1 {
            font-size: 5em;
            margin-bottom: 4%;
            color: $color-3;
            font-family: $font-4;
        }

        p {
            font-size: 1.2em;
            margin-bottom: 1%;
        }

        a {
            text-decoration: none;

            button {
                height: 54px;
                padding: 3% 5%;
                border: none;
                border-radius: 8px;
                cursor: pointer;
                background-color: $color-1;
                color: white;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                transition: background-color 0.3s ease, color 0.3s ease;
                text-decoration: none;
                
                &:hover {
                    text-decoration: none;
                    background-color: #2a9ba0;
                }
            }
        }
    }

    .image-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

@media (min-width: 1601px) {
    .jps-films-banner {

        .content {

            h1 {
                font-size: 1.7rem;
            }

            p {
                font-size: 1.1rem;
            }

            button {
                height: 49px;
                padding: .7rem 1.5rem;
                font-size: 0.8rem;
                margin-top: 5%;
            }
        }
    }
}

@media (max-width: 1600px) {
.jps-films-banner {

        .content {

            h1 {
                font-size: 1.5rem;
            }

            p {
                font-size: 1rem;
                margin-bottom: 2.5%;
            }

            button {
                height: 45px;
                padding: .7rem 1.8rem;
                font-size: 0.7rem;
                margin-top: 3%;
            }
        }
    }
}

@media (max-width: 1200px) {
.jps-films-banner {

        .content {
            
            h1 {
                font-size: 1.3rem;
            }

            p {
                font-size: .9rem;
            }

            button {
                padding: .7rem 1rem;
                margin-top: 3%;
            }
        }
    }
}

@media (max-width: 1115px) {
    .jps-films-banner {

        .content {

            h1 {
                font-size: 1.2rem;
            }

            p {
                font-size: .9rem;
            }

            button {
                padding: .7rem 1.2rem;
                margin-top: 4%;
            }
        }
    }
}

@media (max-width: 915px) {
    .jps-films-banner {

        .content {
            padding-right: 4%;
            
            h1 {
                font-size: 1rem;
            }

            p {
                font-size: .8rem;
            }

            button {
                height: 37px;
                padding: .7rem 1.3rem;
                font-size: 0.55rem;
                margin-top: 4%;
            }
        }
    }
}

@media (max-width: 767px) {
    .jps-films-banner {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 8%;
        
        .image-container {
                order: -1;
                margin-bottom: 1rem;
        }
        
        .content {
            padding-right: 0%;
            margin-bottom: 1rem;
            
            h1 {
                font-size: 1.4rem;
            }
            p {
                font-size: 1rem;
                margin-bottom: 1.2rem;
            }
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                width: 250px;
                height: 54px;
                padding: .7rem 1.3rem;
                font-size: 0.8rem;
                margin-top: 9%;
            }
        }
    }
}