.faq-page {
    position: relative;
    width: 100%;
    overflow: hidden;

    .faq-page__bandeau {
        position: relative;
        width: 100%;

        .bandeau-media__content {
            position: relative;
            width: 100%;
            height: 65vh;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                z-index: 1;
            }
        }

        .bandeau-media__media {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 0;
        }

        .faq-page__overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 2;
            width: 60%;
            padding: 0 20px;
            color: white;

            .faq-page__title {
                font-size: 1.2rem;
                margin: 0;
                text-transform: uppercase;
                margin-bottom: 3%;
                letter-spacing: 0.05em;
            }

            .faq-page__introduction {
                font-size: 2.3rem;
                margin: 0;
                margin-top: 10px;
                font-family: $font-4;
            }
        }
    }
}

@media (max-width: 768px) {

    .faq-page {
            position: relative;
            width: 100%;
            overflow: hidden;
    
            .faq-page__bandeau {
                position: relative;
                z-index: 1;
                
                .bandeau-media__content {
                    position: relative;
                    width: 100%;
                    height: 65vh;
                    overflow: hidden;
                    
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.3);
                        z-index: 1;
                    }
                }
                
                .bandeau-media__media {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: relative;
                    z-index: 0;
                }
    
                .faq-page__overlay {
                    text-align: center;
                    width: 90%;
                    padding: 0 20px;
                    color: white;
    
                    .faq-page__title {
                        font-size: .90rem;
                        margin: 0;
                        text-transform: uppercase;
                        margin-bottom: 3%;
                        letter-spacing: 0.05em;
                    }
    
                    .faq-page__introduction {
                        font-size: 1.6rem;
                        margin: 0;
                        margin-top: 10px;
                    }
                }
            }
        }
}