.navbar {
    display: flex;
    flex-direction: row;
    height: 75px;
    background-color: $color-1;
    color: #fff;

    .sections {
        display: flex;
        justify-content: space-evenly;
        width: 80%;
        height: 100%;
        overflow: hidden;
        margin-left: 2%;

        .section {
            cursor: pointer;
            transition: background-color 0.3s ease;
            font-size: 1.1em;
            line-height: 1.2;
            width: 100%;
            max-width: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 2%;

            &.active {
                background-color: #2a9ba0;
                color: #fff;
            }

                    .section-icon {
                width: auto;
                height: auto;
                max-width: 25px;
                max-height: 25px;
                margin-right: 10px;
            }

            a {
                display: block;
                font-size: .7em;
                line-height: 1.2;
                text-decoration: none;
                color: $color-2;

                span {
                    display: flex;
                    text-align: start;
                }
            }
        }
    }

    .bandeau {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: 100%;
        left: 4%;
        width: 90vw;
        height: 300px;
        background-color: #fff;
        border: 1px solid #ddd;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 25px;
        box-sizing: border-box;
        overflow-x: auto;
        z-index: 5;
    }

    .bandeau-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 18%;
        margin: 10px;
        margin-right: 60px;
        border-radius: 4px;
        padding: 10px;
        box-sizing: border-box;
        text-decoration: none;
        transform: scale(1);
        transition: transform 0.3s ease, color 0.3s ease;

        &:hover {
            transform: scale(1.05);

            h4 {
                color: $color-5;
            }
        }

        a {
            text-decoration: none;
            color: inherit;
            display: block;
        }

        .bandeau-item-image {
            width: 200px;
            height: 120px;
            object-fit: cover;
            margin-bottom: 5px;
            border-radius: 5%;
        }

        .image-placeholder {
            width: 80px;
            height: 50px;
            background-color: #ddd;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            border: 1px solid #ddd;
            font-size: 12px;
            color: black;
        }

        h4 {
            margin: 5px 0;
            font-size: 1rem;
            font-family: $font-2;
            text-align: left;
            color: $color-1;
        }

        p {
            font-size: .8em;
            margin: 0;
            text-align: left;
            width: 100%;
            color: black;
        }
    }

    .guides-regulations {
        font-size: 14px;
        color: $color-5;
        cursor: pointer;
        font-weight: bold;
        margin-left: auto;
        margin-right: 2%;
        white-space: nowrap;
        display: flex;
        align-items: center;
        transition: color 0.3s ease;
        text-decoration: none;

        &:hover {
            color: white;
        }
    }

    @media (max-width: 1200px) {
        .sections {
            width: 90%;

            .section {
                font-size: 1em;
                max-width: 200px;
            }
        }

        .bandeau-item {
            width: 22%;
        }
    }

    @media (max-width: 940px) {
        flex-direction: column;
        height: auto;

        .sections {
            flex-direction: column;
            width: 100%;

            .section {
                padding: 10px 0;
                font-size: 0.9em;
                max-width: 100%;
            }
        }

        .bandeau {
            top: unset;
            left: 0;
            width: 100vw;
            height: auto;
            padding: 15px;
        }

        .bandeau-item {
            width: 45%;
            margin-right: 20px;
            margin-bottom: 20px;
        }

        .guides-regulations {
            font-size: 12px;
            margin-right: 10px;
        }
    }
}