footer {
  font-size: 0.6em;

  .info-band {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    font-size: 1.9em;

    .info-item {
      display: flex;
      align-items: center;
      text-align: center;
      color: $color-1;

      img {
        width: 15px;
        height: auto;
        margin-right: 10px;
        vertical-align: middle;
      }

      p {
        strong {
          font-family: $font-2;
        }
      }
    }
  }

  .top-band {
    background-color: $color-1;
    text-align: center;
    padding: 15px 0;
    display: flex;
    justify-content: space-evenly;

    a {
      margin: 0 15px;
      padding: 0.2%;
      text-decoration: none;
      color: $color-2;
      font-size: 1.2em;
      transition: color 0.3s ease;
    }

    a:hover {
      color: $color-5;
    }
  }

  .main-footer {
    background-color: $color-3;

    .footer-row-large {
      display: flex;
      align-items: flex-start;

      .footer-column-logo {
        padding: 2%;
        padding-left: 5%;

        a {
          img {
            display: flex;
            width: 80%;
          }
        }
      }

      .footer-column {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        margin-right: 2%;
        margin-top: 1%;

        ul {
          .headerFooter {
            font-size: 1.2em;
            text-transform: uppercase;
            margin-top: 20px;
            padding: 0;
            line-height: 1.5;
            height: auto;

            a {
              color: $color-2;
              text-decoration: none;
              font-family: $font-2;
              transition: color 0.3s ease;

              &:hover {
                color: $color-5;
              }
            }
          }

          .liste {
            margin: 5px 0;
            text-align: left;
            font-size: 1.4em;

            a {
              color: $color-2;
              text-decoration: none;
              font-family: $font-3;
              transition: color 0.3s ease;

              &:hover {
                color: $color-5;
              }
            }
          }
        }
      }

      .footer-column-last {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-right: 30px;
        margin-top: 1%;

        ul {
          list-style: none;
          text-decoration: none;
          color: white;
          padding: 0;
          margin: 0;

          .header-last {
            font-size: 1.2em;
            text-transform: uppercase;
            margin-top: 20px;
            padding: 0;
            line-height: 1.5;
            height: auto;

            a {
              color: $color-2;
              text-decoration: none;
              font-family: $font-2;
              transition: color 0.3s ease;

              &:hover {
                color: $color-5;
              }
            }
          }

          .liste-last {
            margin: 5px 0;
            text-align: left;

            a {
              color: $color-2;
              text-decoration: none;
              font-family: $font-3;
              font-size: 1.4em;
              transition: color 0.3s ease;

              &:hover {
                color: $color-5;
              }
            }
          }
        }
      }

      .footer-column-logo-two {
        padding: 2%;
        padding-right: 5%;

        a {
          cursor: default;

          img {
            display: flex;
            width: 80%;
          }
        }
      }
    }

    .footer-separator {
      border-top: 1px solid #ccc;
    }

    .footer-row-links {
      margin: 20px 0;
      color: $color-2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .footer-follow {
      display: flex;
      align-items: center;
      margin: 0 40px;
      font-size: 1.5em;

      .follow {
        margin-right: 45px;
        text-decoration: none;
        color: white;
        font-family: $font-4;
        cursor: default;
      }

      .social-link {
        display: inline-flex;
        align-items: center;
        margin: 0 20px;
        text-decoration: none;
        color: $color-2;
        font-family: $font-5;
        font-size: 0.8em;
        transition: color 0.3s ease;

        img {
          width: 15px;
          height: auto;
          margin-right: 10px;
          vertical-align: middle;
        }

        &:hover {
          color: $color-5;
        }
      }
    }

    .footer-contact {
      display: flex;
      align-items: center;
      font-size: 1.5em;

      a {
        text-decoration: none;
        color: white;
        font-family: $font-4;
        width: 200px;
        margin-left: 3%;
        cursor: default;
      }

      a:nth-of-type(2) {
        text-decoration: none;
        color: white;
        font-family: $font-5;
        font-size: 0.8em;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: $color-5;
        }
      }
    }
  }

  .footer-separator-two {
    border-top: 1px solid #cccccc8e;
  }

  .footer-row-small {
    text-align: center;
    padding: 18px 0;

    a {
      margin: 0 15px;
      text-decoration: none;
      color: white;
      transition: color 0.3s ease;
    }

    a:hover {
      color: $color-5;
    }
  }

  .main-footer
    a:not(.follow, .footer-contact a, .footer-column-logo-two a, .top-band a) {
    transition: color 0.3s ease;
  }

  .main-footer
    a:not(
      .follow,
      .footer-contact a,
      .footer-column-logo-two a,
      .top-band a
    ):hover {
    color: $color-5;
  }
}

@media (max-width: 1600px) {
  footer {
    font-size: 0.6em;

    .info-band {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 20px;
      font-size: 1.9em;

      .info-item {
        display: flex;
        align-items: center;
        text-align: center;
        color: $color-1;

        img {
          width: 15px;
          height: auto;
          margin-right: 10px;
          vertical-align: middle;
        }

        p {
          strong {
            font-family: $font-2;
          }
        }
      }
    }

    .top-band {
      background-color: $color-1;
      text-align: center;
      padding: 15px 0;
      display: flex;
      justify-content: space-evenly;

      a {
        margin: 0 15px;
        padding: 0.2%;
        text-decoration: none;
        color: $color-2;
        font-size: 1.2em;
        transition: color 0.3s ease;
      }

      a:hover {
        color: $color-5;
      }
    }

    .main-footer {
      background-color: $color-3;

      .footer-row-large {
        display: flex;
        align-items: flex-start; // Aligne les éléments en haut

        .footer-column-logo {
          padding: 2%;
          padding-left: 5%;

          a {
            img {
              display: flex;
              width: 80%;
            }
          }
        }

        .footer-column {
          flex: 1;
          display: flex;
          flex-direction: column;
          text-align: left;
          justify-content: center;
          margin-right: 1%;
          margin-top: 1%;

          ul {
            .headerFooter {
              font-size: 1.2em;
              text-transform: uppercase;
              margin-top: 20px;
              padding: 0;
              line-height: 1.5;
              height: auto;

              a {
                color: $color-2;
                text-decoration: none;
                font-family: $font-2;
              }
            }

            .liste {
              margin: 5px 0;
              text-align: left;
              font-size: 1.4em;

              a {
                color: $color-2;
                text-decoration: none;
                font-family: $font-3;
              }
            }
          }
        }

        .footer-column-last {
          flex: 1;
          display: flex;
          flex-direction: column;
          text-align: left;
          margin-right: 30px;
          margin-top: 1%;

          ul {
            list-style: none;
            text-decoration: none;
            color: white;
            padding: 0;
            margin: 0;

            .header-last {
              font-size: 1.2em;
              text-transform: uppercase;
              margin-top: 20px;
              padding: 0;
              line-height: 1.5;
              height: auto;

              a {
                color: $color-2;
                text-decoration: none;
                font-family: $font-2;
              }
            }

            .liste-last {
              margin: 5px 0;
              text-align: left;

              a {
                color: $color-2;
                text-decoration: none;
                font-family: $font-3;
                font-size: 1.4em;
                transition: color 0.3s ease;

                &:hover {
                  color: $color-5;
                }
              }
            }
          }
        }

        .footer-column-logo-two {
          padding: 2%;
          padding-right: 5%;

          a {
            cursor: default;

            img {
              display: flex;
              width: 80%;
            }
          }
        }
      }

      .footer-separator {
        border-top: 1px solid #ccc;
      }

      .footer-row-links {
        margin: 20px 0;
        color: $color-2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }

      .footer-follow {
        display: flex;
        align-items: center;
        margin: 0 40px;
        font-size: 1.5em;

        .follow {
          margin-right: 45px;
          text-decoration: none;
          color: white;
          font-family: $font-4;
          cursor: default;
        }

        .social-link {
          display: inline-flex;
          align-items: center;
          margin: 0 20px;
          text-decoration: none;
          color: $color-2;
          font-family: $font-5;
          font-size: 0.8em;
          transition: color 0.3s ease;

          img {
            width: 15px;
            height: auto;
            margin-right: 10px;
            vertical-align: middle;
          }

          &:hover {
            color: $color-5;
          }
        }
      }

      .footer-contact {
        display: flex;
        align-items: center;
        font-size: 1.5em;

        a {
          text-decoration: none;
          color: white;
          font-family: $font-4;
          width: 200px;
          margin-left: 3%;
          cursor: default;
        }

        a:nth-of-type(2) {
          text-decoration: none;
          color: white;
          font-family: $font-5;
          font-size: 0.8em;
          cursor: pointer;
          transition: color 0.3s ease;

          &:hover {
            color: $color-5;
          }
        }
      }
    }

    .footer-separator-two {
      border-top: 1px solid #cccccc8e;
    }

    .footer-row-small {
      text-align: center;
      padding: 18px 0;

      a {
        margin: 0 15px;
        text-decoration: none;
        color: white;
        transition: color 0.3s ease;
      }

      a:hover {
        color: $color-5;
      }
    }

    .main-footer
      a:not(
        .follow,
        .footer-contact a,
        .footer-column-logo-two a,
        .top-band a
      ) {
      transition: color 0.3s ease;
    }

    .main-footer
      a:not(
        .follow,
        .footer-contact a,
        .footer-column-logo-two a,
        .top-band a
      ):hover {
      color: $color-5;
    }
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1115px) {
}

@media (max-width: 915px) {
}

@media (max-width: 768px) {
  footer {
    font-size: 0.7em;

    .info-band {
      display: flex;
      align-items: flex-start;
      padding: 40px;
      padding-bottom: 7%;
      font-size: 1.5em;
      flex-direction: column;

      .info-item {
        margin-bottom: 3%;
      }
    }
    .top-band {
      flex-direction: column;
      padding: 5% 0%;
      padding-top: 7%;
      font-size: 1.2em;
      a {
        margin-bottom: 3%;
      }
    }

    .main-footer {
      background-color: $color-3;

      .footer-row-large {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        align-items: start;
        justify-items: start;
        grid-template-areas:
          "logo1 logo2"
          "text text";
        padding-left: 6%;
        padding-top: 6%;

        .footer-column-logo {
          grid-area: logo1;
          width: 120%;
          margin-left: 0;

          a {
            img {
              display: flex;
              width: 80%;
            }
          }
        }

        .footer-column-logo-two {
          grid-area: logo2;
          width: 78%;
          margin-left: 15%;
          margin-bottom: 6%;
        }

        .footer-column {
          flex: 1;
          text-align: left;
          margin: auto;
          margin-left: 6%;
          margin-right: 6%;

          ul {
            .headerFooter {
              font-size: 1.2em;
              text-transform: uppercase;
              padding: 0;
              line-height: 1.5;
              height: auto;

              a {
                color: $color-2;
                text-decoration: none;
                font-family: $font-2;
              }
            }

            .liste {
              margin: 8px 0;
              text-align: left;
              font-size: 1.3em;

              a {
                color: $color-2;
                text-decoration: none;
                font-family: $font-3;
              }
            }
          }
        }

        .footer-column-last {
          margin-left: 8%;

          ul {
            list-style: none;
            text-decoration: none;
            color: white;
            padding: 0;
            margin: 0;

            .header-last {
              font-size: 1.2em;
              text-transform: uppercase;
              margin-top: 20px;
              padding: 0;
              line-height: 1.5;
              height: auto;

              a {
                color: $color-2;
                text-decoration: none;
                font-family: $font-2;
              }
            }

            .liste-last {
              margin: 8px 0;
              text-align: left;

              a {
                color: $color-2;
                text-decoration: none;
                font-family: $font-3;
                font-size: 1.3em;
              }
            }
          }
        }
      }

      .footer-separator {
        width: 100%;
        border-top: 1px solid #ccc;
        margin-top: 9%;
      }

      .footer-row-links {
        margin: 20px 0;
        color: $color-2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }

      .footer-follow {
        display: flex;
        flex-direction: column;
        font-size: 1.5em;

        .follow {
          text-decoration: none;
          margin: auto;
          color: white;
          font-family: $font-4;
          cursor: default;
          margin-bottom: 20px;
        }

        .social-link {
          display: flex;
          margin: 0px 0;
          text-decoration: none;
          color: $color-2;
          font-family: $font-5;
          font-size: 1em;

          img {
            width: 25px;
            height: auto;
            margin-right: 10px;
            vertical-align: middle;
          }
        }

        .footer-social-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px 45px;
          width: 117%;
          justify-items: flex-start;
          justify-content: flex-start;
          padding: 0;
          margin: 0;
        }
      }

      .footer-contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.5em;
        margin-top: 5%;

        a {
          margin: 10px 0;
          text-align: center;
        }
      }
    }

    .footer-separator-two {
      border-top: 1px solid #cccccc8e;
    }

    .footer-row-small {
      text-align: center;
      padding: 18px 0;

      a {
        margin: 0 15px;
        text-decoration: none;
        color: white;
      }
    }
  }
}
