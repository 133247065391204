.guides-reglementations {
    position: relative;
    width: 100%;
    overflow: hidden;

    .guides-reglementations__bandeau {
        position: relative;
        width: 100%;

        .bandeau-media__content {
            position: relative;
            width: 100%;
            height: 65vh;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                z-index: 1;
            }
        }

        .bandeau-media__media {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 0;
        }

        .guides-reglementations__overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 2;
            width: 60%;
            padding: 0 20px;
            color: white;

            .guides-reglementations__title {
                font-size: 1.2rem;
                margin: 0;
                text-transform: uppercase;
                margin-bottom: 3%;
                letter-spacing: 0.05em;
                font-family: $font-3;
            }

            .guides-reglementations__introduction {
                font-size: 2.3rem;
                margin: 0;
                margin-top: 10px;
                font-family: $font-2;
            }
        }
    }

    .guides-reglementations__content {
        padding: 80px;

        h3,
        h4 {
            font-family: $font-4;
            font-size: 1.5em;
            color: $color-3;
            margin: 3% 0;
        }

        h5 {
            margin: 20px 0 10px;
            font-size: 1.1em;
            margin: 2% 0;
            font-family: $font-4;
        }

        ul {
            padding-left: 20px;
        }

        li {
            list-style-type: disc;
            line-height: 125%;
            font-family: $font-1;
            font-size: 1.1em;
        }

        p {
            margin: 10px 0;
            font-family: $font-1;
            font-size: 1.1em;
        }
    }
}

.floating-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1001;
}

@media (max-width: 768px) {

    .guides-reglementations {
        position: relative;
        width: 100%;
        overflow: hidden;

        .guides-reglementations__bandeau {
            width: 100%;
            z-index: 1;

            .bandeau-media__content {
                position: relative;
                width: 100%;
                height: 50vh;
                overflow: hidden;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.3); 
                    z-index: 1;
                }
            }

            .bandeau-media__media {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
                z-index: 0;
            }

            .guides-reglementations__overlay {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                z-index: 2; 
                width: 80%;
                padding: 0 10px;
                color: white;

                .guides-reglementations__title {
                    font-size: 0.8rem;
                    margin: 0;
                    text-transform: uppercase;
                    margin-bottom: 2%;
                    letter-spacing: 0.05em;
                    font-family: $font-3;
                }

                .guides-reglementations__introduction {
                    font-size: 1.5rem;
                    margin: 0;
                    margin-top: 5px;
                    font-family: $font-4;
                }
            }
        }

        .guides-reglementations__content {
            position: relative;
            padding: 20px;
            font-family: $font-4;
            margin-top: -40px;
            background-color: white;
            z-index: 2;
            border-radius: 20px 20px 0 0;

            h3,
            h4 {
                font-family: $font-4;
                font-size: 1.2rem;
                color: $color-3;
                margin: 6% 0;
            }

            h5 {
                margin: 15px 0 5px;
                font-size: 1rem;
                margin: 4% 0;
            }

            ul {
                padding-left: 25px;
                width: 97%;
                margin: 4% 0;
            }

            li {
                list-style-type: disc;
                line-height: 1.4;
            }

            p {
                margin: 5px 0;
            }
        }
    }

    .floating-buttons {
        position: fixed;
        bottom: 15px;
        right: 15px; 
        z-index: 1001;
    }
}