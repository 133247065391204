.sous-section-descriptifs {
  padding: 20px 80px;
  width: 800px;

  h1 {
    font-size: 1rem;
    margin-bottom: 20px;
    color: $color-3;
    font-family: $font-3;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .title-h3 {
    font-size: 1.5rem;
    margin: 15px 0px;
    color: $color-3;
    font-family: $font-4;
  }

  .title-h5 {
    font-size: 1.1rem;
    margin-bottom: 10px;
    font-family: $font-4;
  }

  .paragraph {
    font-size: 1.1rem;
    margin-bottom: 15px;
    color: black;
    white-space: pre-line;
  }

  .list {
    list-style: none;
    padding: 0;
    margin-bottom: 15px;

    .list-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 1.1rem;

      .icon {
        margin-right: 13px;
        font-size: 1rem;
      }
    }
  }

  .bloc-texte-un,
  .bloc-texte-deux,
  .bloc-texte-trois {
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
  }
  video {
    max-width: 100%;
  }
}

@media (min-width: 1652px) {
  .sous-section-descriptifs {
    width: 1100px;

    h1 {
      font-size: 1rem;
      margin-bottom: 20px;
      color: $color-3;
      font-family: $font-3;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    .title-h3 {
      font-size: 1.4rem;
      margin: 15px 0px;
      color: $color-3;
      font-family: $font-4;
    }

    .title-h5 {
      font-size: 1rem;
      margin-bottom: 10px;
      font-family: $font-4;
    }

    .paragraph {
      font-size: 1rem;
      margin-bottom: 15px;
      color: #333;
      white-space: pre-line;
    }

    .list {
      margin-bottom: 15px;

      .list-item {
        margin-bottom: 10px;
        font-size: 1rem;

        .icon {
          margin-right: 13px;
          font-size: 1rem;
        }
      }
    }

    .bloc-texte-un,
    .bloc-texte-deux,
    .bloc-texte-trois {
      margin-bottom: 20px;
    }

    img {
      max-width: 100%;
    }
  }
}

@media (max-width: 1653px) {
  .sous-section-descriptifs {
    padding: 25px 90px;
    width: 1000px;
  }
}

@media (max-width: 1556px) {
  .sous-section-descriptifs {
    max-width: 900px;
  }
}

@media (max-width: 1472px) {
  .sous-section-descriptifs {
    max-width: 800px;
  }
}

@media (max-width: 1367px) {
  .sous-section-descriptifs {
    max-width: 700px;

    h1 {
      font-size: 0.9rem;
    }

    .title-h3 {
      font-size: 1.3rem;
    }

    .title-h5 {
      font-size: 0.9rem;
    }

    .paragraph {
      font-size: 0.9rem;
    }

    .list .list-item {
      font-size: 0.9rem;

      .icon {
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 1256px) {
  .sous-section-descriptifs {
    max-width: 660px;
  }
}

@media (max-width: 1200px) {
  .sous-section-descriptifs {
    max-width: 660px;
  }
}

@media (max-width: 1115px) {
  .sous-section-descriptifs {
    padding: 15px 60px;
    max-width: 600px;
  }
}

@media (max-width: 1024px) {
  .sous-section-descriptifs {
    padding: 10px 40px;
    max-width: 520px;

    h1 {
      font-size: 0.8rem;
    }

    .title-h3 {
      font-size: 1.2rem;
    }

    .title-h5 {
      font-size: 0.8rem;
    }

    .paragraph {
      font-size: 0.8rem;
    }

    .list .list-item {
      font-size: 0.8rem;

      .icon {
        font-size: 0.8rem;
      }
    }
  }
}

@media (max-width: 950px) {
  .sous-section-descriptifs {
    max-width: 500px;

    h1 {
      font-size: 0.8rem;
    }

    .title-h3 {
      font-size: 1.2rem;
    }

    .title-h5 {
      font-size: 0.8rem;
    }

    .paragraph {
      font-size: 0.8rem;
    }

    .list .list-item {
      font-size: 0.8rem;

      .icon {
        font-size: 0.8rem;
      }
    }
  }
}

@media (max-width: 915px) {
  .sous-section-descriptifs {
    max-width: 480px;

    h1 {
      font-size: 0.8rem;
    }

    .title-h3 {
      font-size: 1.2rem;
    }

    .title-h5 {
      font-size: 0.8rem;
    }

    .paragraph {
      font-size: 0.8rem;
    }

    .list .list-item {
      font-size: 0.8rem;

      .icon {
        font-size: 0.8rem;
      }
    }
  }
}

@media (max-width: 900px) {
  .sous-section-descriptifs {
    max-width: 450px;

    h1 {
      font-size: 0.8rem;
    }

    .title-h3 {
      font-size: 1.2rem;
    }

    .title-h5 {
      font-size: 0.8rem;
    }

    .paragraph {
      font-size: 0.8rem;
    }

    .list .list-item {
      font-size: 0.8rem;

      .icon {
        font-size: 0.8rem;
      }
    }
  }
}

@media (max-width: 850px) {
  .sous-section-descriptifs {
    max-width: 400px;

    h1 {
      font-size: 0.8rem;
    }

    .title-h3 {
      font-size: 1rem;
    }

    .title-h5 {
      font-size: 0.8rem;
    }

    .paragraph {
      font-size: 0.7rem;
    }

    .list .list-item {
      font-size: 0.7rem;

      .icon {
        font-size: 0.7rem;
      }
    }
  }
}

@media (max-width: 767px) {
  .sous-section-descriptifs {
    padding: 10% 0%;
    max-width: 100%;

    h1 {
      font-size: 0.8rem;
      margin-bottom: 10px;
      padding: 0% 6%;
    }

    .title-h3 {
      font-size: 1.4rem;
      margin-bottom: 30px;
      padding: 0% 6%;
    }

    .title-h5 {
      font-size: 1rem;
      margin-bottom: 10px;
      padding: 0% 6%;
    }

    .paragraph {
      font-size: 1rem;
      margin-bottom: 15px;
      padding: 0% 6%;
    }

    .list {
      margin-bottom: 20px;
      margin-top: 20px;
      padding: 0% 6%;

      .list-item {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        font-size: 0.9rem;

        .icon {
          margin-right: 5px;
          font-size: 0.7rem;
        }
      }
    }

    .bloc-texte-un,
    .bloc-texte-deux,
    .bloc-texte-trois {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      margin: 0;
    }
  }
}
