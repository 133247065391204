.bandeau-descriptif-un {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding-left: 4%;
  position: relative;

  .text-section-bandUn {
    flex: 1;

    .introduction {
      font-size: 0.9em;
      color: $color-1;
      margin-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    .titre {
      font-size: 2.5em;
      margin-bottom: 20px;
      width: 80%;
      font-family: $font-1;
    }

    p {
      margin-bottom: 20px;
      width: 80%;
      font-family: $font-1;
    }

    .descriptif {
      width: 80%;
      margin-bottom: 7%;

      .descriptif-liste {
        margin-bottom: 4%;
      }
    }

    .liste {
      list-style: none;
      padding: 0;
      margin-bottom: 6%;
      line-height: 1em;
      font-family: $font-1;

      li {
        margin-bottom: 2%;
        display: flex;
        align-items: center;
        font-family: $font-1;

        i {
          color: black;
          font-size: 1em;
          margin-right: 2%;
        }
      }
    }

    .buttons {
      display: flex;
      gap: 10px;

      .btn-primary,
      .btn-secondary {
        width: 28%;
        height: 52px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 0.85em;
        font-family: $font-3;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.05em;
      }

      .btn-primary {
        background-color: $color-1;
        color: #fff;
        margin-right: 4%;
        transition: background-color 0.3s ease;

        &:hover {
          text-decoration: none;
          background-color: #2a9ba0;
        }
      }

      .btn-secondary {
        background-color: white;
        color: $color-1;
        border: 1px solid $color-1;
        font-family: $font-4;
        transition: color 0.3s ease, background-color 0.3s ease;

        &:hover {
          text-decoration: none;
          background-color: $color-1;
          color: white;
        }
      }
    }
  }

  .image-section {
    flex: 1;
    position: relative;

    .image-container {
      position: relative;
      width: 100%;
      height: 555px;
      overflow: hidden;

      .bandeau-image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            to left,
            transparent 45%,
            rgba(255, 255, 255, 1) 100%
          );
          z-index: 1;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: brightness(95%) contrast(120%);
          z-index: 2;
          background: linear-gradient(
            to bottom right,
            transparent 37%,
            rgba(0, 0, 0, 0.568) 90%,
            rgba(0, 0, 0, 0.342) 100%
          );
        }
      }

      // .logos {
      //     position: absolute;
      //     bottom: 20px;
      //     right: 340px;
      //     display: flex;
      //     align-items: center;
      //     gap: 25px;
      //     width: 80px;
      //     z-index: 6;

      //     img {
      //         width: 100%;
      //     }
      // }

      .logos {
        position: absolute;
        bottom: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 25px;
        flex-wrap: wrap;
        z-index: 6;

        img {
          max-width: 10vw;
          height: auto;
        }
      }
    }
  }
}

@media (min-width: 1601px) {
  .bandeau-descriptif-un {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    padding-left: 4%;
    position: relative;

    .text-section-bandUn {
      flex: 1;

      .introduction {
        font-size: 1.1em;
        color: $color-3;
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }

      .titre {
        font-size: 2.5em;
        color: #111;
        margin-bottom: 20px;
        width: 80%;
        font-family: $font-1;
      }

      p {
        color: black;
        margin-bottom: 20px;
        width: 80%;
        font-family: $font-1;
      }

      .descriptif {
        width: 80%;
        margin-bottom: 7%;

        .descriptif-liste {
          margin-bottom: 4%;
        }
      }

      .liste {
        list-style: none;
        padding: 0;
        margin-bottom: 6%;
        margin-bottom: 4%;
        line-height: 1em;
        font-family: $font-2;

        li {
          margin-bottom: 2%;
          display: flex;
          align-items: center;
          font-family: $font-1;
          font-size: 1.1em;

          i {
            color: black;
            font-size: 1em;
            margin-right: 2%;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 10px;

        .btn-primary,
        .btn-secondary {
          width: 24%;
          height: 52px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 0.85em;
          font-family: $font-3;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.05em;
        }

        .btn-primary {
          background-color: $color-1;
          color: #fff;
          margin-right: 4%;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
            background-color: #2a9ba0;
          }
        }

        .btn-secondary {
          background-color: #fff;
          color: $color-1;
          border: 1px solid $color-1;
          font-family: $font-4;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .image-section {
      flex: 1;
      position: relative;

      .image-container {
        position: relative;
        width: 100%;
        height: 555px;
        overflow: hidden;

        .bandeau-image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              to left,
              transparent 45%,
              rgba(255, 255, 255, 1) 100%
            );
            z-index: 1;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: brightness(95%) contrast(120%);
            z-index: 2;
          }
        }

        // .logos {
        //     position: absolute;
        //     bottom: 20px;
        //     right: 400px;
        //     display: flex;
        //     align-items: center;
        //     gap: 30px;
        //     width: 80px;
        //     z-index: 6;

        //     img {
        //         width: 110%;
        //     }
        // }
      }
    }
  }
}

@media (max-width: 1600px) {
  .bandeau-descriptif-un {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    padding-left: 4%;
    position: relative;

    .text-section-bandUn {
      flex: 1;

      .introduction {
        font-size: 1em;
        color: $color-3;
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }

      .titre {
        font-size: 2.3em;
        color: black;
        margin-bottom: 20px;
        width: 80%;
        font-family: $font-1;
      }

      p {
        color: black;
        margin-bottom: 20px;
        width: 80%;
        font-family: $font-1;
      }

      .descriptif {
        width: 80%;
        margin-bottom: 7%;

        .descriptif-liste {
          margin-bottom: 4%;
        }
      }

      .liste {
        list-style: none;
        padding: 0;
        margin-bottom: 6%;
        margin-bottom: 4%;
        line-height: 1em;
        font-family: $font-1;

        li {
          margin-bottom: 2%;
          display: flex;
          align-items: center;
          font-family: $font-1;
          font-size: 1em;

          i {
            color: black;
            font-size: 0.9em;
            margin-right: 2%;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 10px;

        .btn-primary,
        .btn-secondary {
          width: 30%;
          height: 52px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 0.8em;
          font-family: $font-3;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.05em;
        }

        .btn-primary {
          background-color: $color-1;
          color: #fff;
          margin-right: 4%;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
            background-color: #2a9ba0;
          }
        }

        .btn-secondary {
          background-color: #fff;
          color: $color-1;
          border: 1px solid $color-1;
          font-family: $font-4;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .image-section {
      flex: 1;
      position: relative;

      .image-container {
        position: relative;
        width: 100%;
        height: 555px;
        overflow: hidden;

        .bandeau-image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              to left,
              transparent 45%,
              rgba(255, 255, 255, 1) 100%
            );
            z-index: 1;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: brightness(95%) contrast(120%);
            z-index: 2;
          }
        }

        // .logos {
        //     position: absolute;
        //     bottom: 20px;
        //     right: 340px;
        //     display: flex;
        //     align-items: center;
        //     gap: 25px;
        //     width: 80px;
        //     z-index: 6;

        //     img {
        //         width: 100%;
        //     }
        // }
      }
    }
  }
}

@media (max-width: 1200px) {
  .bandeau-descriptif-un {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    padding-left: 4%;
    position: relative;

    .text-section-bandUn {
      flex: 1;

      .introduction {
        font-size: 0.9em;
        color: $color-3;
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }

      .titre {
        font-size: 2.1em;
        color: #111;
        margin-bottom: 20px;
        width: 80%;
        font-family: $font-1;
      }

      p {
        color: #111;
        margin-bottom: 20px;
        width: 80%;
        font-family: $font-1;
      }

      .descriptif {
        width: 80%;
        margin-bottom: 7%;

        .descriptif-liste {
          margin-bottom: 4%;
        }
      }

      .liste {
        list-style: none;
        padding: 0;
        margin-bottom: 5%;
        line-height: 0.9em;
        font-family: $font-1;

        li {
          margin-bottom: 2%;
          display: flex;
          align-items: center;
          font-family: $font-1;
          font-size: 0.9em;

          i {
            color: black;
            font-size: 0.9em;
            margin-right: 2%;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 10px;

        .btn-primary,
        .btn-secondary {
          width: 30%;
          height: 52px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 0.7em;
          font-family: $font-3;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.05em;
        }

        .btn-primary {
          background-color: $color-1;
          color: #fff;
          margin-right: 4%;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
            background-color: #2a9ba0;
          }
        }

        .btn-secondary {
          background-color: #fff;
          color: $color-1;
          border: 1px solid $color-1;
          font-family: $font-4;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .image-section {
      flex: 1;
      position: relative;

      .image-container {
        position: relative;
        width: 100%;
        height: 555px;
        overflow: hidden;

        .bandeau-image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              to left,
              transparent 45%,
              rgba(255, 255, 255, 1) 100%
            );
            z-index: 1;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: brightness(95%) contrast(120%);
            z-index: 2;
          }
        }

        // .logos {
        //     position: absolute;
        //     bottom: 20px;
        //     right: 300px;
        //     display: flex;
        //     align-items: center;
        //     gap: 25px;
        //     width: 80px;
        //     z-index: 6;

        //     img {
        //         width: 80%;
        //     }
        // }
      }
    }
  }
}

@media (max-width: 1115px) {
  .bandeau-descriptif-un {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    padding-left: 4%;
    position: relative;

    .text-section-bandUn {
      flex: 1;

      .introduction {
        font-size: 0.85em;
        color: $color-3;
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }

      .titre {
        font-size: 2.1em;
        color: #111;
        margin-bottom: 20px;
        width: 84%;
        font-family: $font-1;
      }

      p {
        color: #111;
        margin-bottom: 20px;
        width: 80%;
        font-family: $font-1;
      }

      .descriptif {
        width: 80%;
        margin-bottom: 7%;

        .descriptif-liste {
          margin-bottom: 4%;
        }
      }

      .liste {
        list-style: none;
        padding: 0;
        margin-bottom: 5%;
        line-height: 0.9em;
        font-family: $font-1;

        li {
          margin-bottom: 2%;
          display: flex;
          align-items: center;
          font-family: $font-1;
          font-size: 0.9em;

          i {
            color: black;
            font-size: 0.9em;
            margin-right: 2%;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 10px;

        .btn-primary,
        .btn-secondary {
          width: 34%;
          height: 52px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 0.7em;
          font-family: $font-3;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.05em;
        }

        .btn-primary {
          background-color: $color-1;
          color: #fff;
          margin-right: 4%;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
            background-color: #2a9ba0;
          }
        }

        .btn-secondary {
          background-color: #fff;
          color: $color-1;
          border: 1px solid $color-1;
          font-family: $font-4;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .image-section {
      flex: 1;
      position: relative;

      .image-container {
        position: relative;
        width: 100%;
        height: 555px;
        overflow: hidden;

        .bandeau-image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              to left,
              transparent 45%,
              rgba(255, 255, 255, 1) 100%
            );
            z-index: 1;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: brightness(95%) contrast(120%);
            z-index: 2;
          }
        }

        // .logos {
        //     position: absolute;
        //     bottom: 20px;
        //     right: 230px;
        //     display: flex;
        //     align-items: center;
        //     gap: 20px;
        //     width: 80px;
        //     z-index: 6;

        //     img {
        //         width: 70%;
        //     }
        // }
      }
    }
  }
}

@media (max-width: 915px) {
  .bandeau-descriptif-un {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    padding-left: 4%;
    position: relative;

    .text-section-bandUn {
      flex: 1;

      .introduction {
        font-size: 0.8em;
        color: $color-3;
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }

      .titre {
        font-size: 1.9em;
        color: #111;
        margin-bottom: 20px;
        width: 100%;
        font-family: $font-1;
      }

      p {
        color: #111;
        margin-bottom: 20px;
        width: 80%;
        font-family: $font-1;
      }

      .descriptif {
        width: 80%;
        margin-bottom: 7%;

        .descriptif-liste {
          margin-bottom: 4%;
        }
      }

      .liste {
        list-style: none;
        padding: 0;
        margin-bottom: 7%;
        line-height: 0.9em;
        font-family: $font-1;

        li {
          margin-bottom: 2%;
          display: flex;
          align-items: center;
          font-family: $font-1;
          font-size: 0.8em;

          i {
            color: black;
            font-size: 0.9em;
            margin-right: 2%;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 10px;

        .btn-primary,
        .btn-secondary {
          width: 40%;
          height: 48px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 0.7em;
          font-family: $font-3;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.05em;
        }

        .btn-primary {
          background-color: $color-1;
          color: #fff;
          margin-right: 4%;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
            background-color: #2a9ba0;
          }
        }

        .btn-secondary {
          background-color: #fff;
          color: $color-1;
          border: 1px solid $color-1;
          font-family: $font-4;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .image-section {
      flex: 1;
      position: relative;

      .image-container {
        position: relative;
        width: 100%;
        height: 555px;
        overflow: hidden;

        .bandeau-image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              to left,
              transparent 45%,
              rgba(255, 255, 255, 1) 100%
            );
            z-index: 1;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: brightness(95%) contrast(120%);
            z-index: 2;
          }
        }

        // .logos {
        //     position: absolute;
        //     bottom: 20px;
        //     right: 180px;
        //     display: flex;
        //     align-items: center;
        //     gap: 15px;
        //     width: 80px;
        //     z-index: 6;

        //     img {
        //         width: 60%;
        //     }
        // }
      }
    }
  }
}

@media (max-width: 767px) {
  .bandeau-descriptif-un {
    padding: 0;
    height: 800px;
    position: relative;
    z-index: 1;
    background-color: $color-1;

    .image-section {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 340px;
      overflow: hidden;
      z-index: 1;

      .image-container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .bandeau-image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              to bottom,
              rgba($color-1, 0) 10%,
              $color-1 100%
            );
            z-index: 1;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: none;
            z-index: 2;
            background: none;
          }
        }
        .logos {
          display: none;
          img {
            width: 60%;
          }
        }
      }
    }

    .text-section-bandUn {
      position: absolute;
      top: 32%;
      left: 0;
      width: 100%;
      height: auto;
      background: transparent;
      z-index: 3;
      padding: 10px 20px;
      color: #fff;
      text-align: left;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .introduction {
        font-size: 0.8em;
        color: rgba(255, 255, 255, 0.848);
        width: 100%;
      }

      .titre {
        font-size: 1.4em;
        margin-bottom: 15px;
        color: #fff;
      }

      p {
        font-size: 0.8em;
        margin-bottom: 15px;
        color: #fff;
      }

      .descriptif {
        width: 100%;
        margin-bottom: 7%;

        .descriptif-liste {
          margin-bottom: 4%;
        }
      }

      .liste {
        list-style: none;
        padding: 0;
        margin-bottom: 6%;
        line-height: 1em;
        font-family: $font-1;

        li {
          font-size: 0.9em;
          font-family: $font-1;
          margin-bottom: 3.5%;

          i {
            color: rgba(255, 255, 255, 0.5);
            font-size: 0.8em;
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;

        .btn-primary {
          width: 250px;
          height: 50px;
          background-color: $color-5;
          color: #fff;
          border: none;
          border-radius: 5px;
          font-size: 0.8em;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: none;
            background-color: #2a9ba0;
          }
        }

        .btn-secondary {
          display: none;
        }
      }
    }
  }
}
