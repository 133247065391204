.error-container {
    margin: 0 auto;
    height: 48vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .img-error {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 2rem;
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
    }

    .error-content {
        position: relative;
        z-index: 10;
        width: 50%;
        text-align: center;
        padding: 2%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            color: $color-1;
            margin-bottom: 1.5rem;
            font-family: $font-2;
            font-size: 1.6em;
        }

        p {
            color: black;
            margin-bottom: 2rem;
            font-size: 1.2em;
        }

        a {
            display: inline-block;
            background-color: $color-1;
            color: white;
            padding: 1.1rem 2rem;
            border-radius: 5px;
            text-decoration: none;
            font-size: 0.8rem;
            width: auto;
            margin: auto;
            transition: background-color 0.3s ease;
            margin-top: 1.3em;

            &:hover {
                background-color: $color-5;
            }
        }
    }
}