.nos-articles {

    .nos-articles__header {
        display: flex;
        justify-content: flex-start;
        padding: 20px;
        margin-left: 3%;
        margin-top: 1%;
        position: relative;

        .nos-articles__filters {
            display: flex;
            gap: 25px;
            font-size: 1.4em;

            .nos-articles__filter {
                text-decoration: none;
                color: black;
                font-weight: bold;
                cursor: pointer;
                padding: 5px 10px;
                border-radius: 5px;
                transition: font-weight 0.3s ease, font-size 0.3s ease, background-color 0.3s ease;

                &:hover {
                    font-size: 1.05em;
                    font-weight: bold;
                }

                &.active {
                    color: $color-1;
                    font-family: $font-2;
                }
            }
        }

        .nos-articles__filter-menu {
            position: relative;

            .nos-articles__filter-toggle {
                background: none;
                border: none;
                font-size: 1.4em;
                cursor: pointer;
                display: flex;
                align-items: center;

                .fa-chevron-down,
                .fa-chevron-up {
                    margin-left: 10px;
                }
            }

            .nos-articles__filter-dropdown {
                position: absolute;
                top: 100%;
                left: 0;
                background: #fff;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                z-index: 1000;

                .nos-articles__filter-item {
                    padding: 10px;
                    cursor: pointer;
                    border-bottom: 1px solid #ddd;

                    &:last-child {
                        border-bottom: none;
                    }

                    &.active {
                        background-color: $color-1;
                        color: #fff;
                    }

                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
            }
        }
    }

    .nos-articles__content {
        display: flex;
        width: 91%;
        margin-left: 3%;

        .nos-articles__aside {
            position: relative;
            display: flex;
            flex: 1;
            padding-top: 20px;
            max-width: 320px;
        }

        .jps-metaview-wrapper {
            position: relative;
            max-width: 320px;
            margin-top: 6%;
            transition: position 0.3s ease, top 0.3s ease;

            &.jps-metaview-fixed {
                position: fixed;
                top: 200px;
                width: 100%;
                max-width: 320px;
                z-index: 997;
            }

            &.jps-metaview-stopped {
                position: absolute;
                bottom: 0;
                top: auto;
                width: 100%;
                max-width: 320px;
            }
        }

        .nos-articles__main {
            flex: 3;
            padding: 20px;

            .nos-articles__grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 35px;
            }
        }
    }
}

@media (max-width: 768px) {
    .nos-articles {

        .nos-articles__header {
            display: block;
            margin-left: 0%;
        }

        .filter-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            margin-top: 10%;
            padding: 2% 0%;
            margin: 2% 2%;
            position: relative;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;

            h4 {
                margin: 0;
                flex-grow: 1;
                text-align: left;
                font-family: $font-2;
                color: $color-1;
            }

            .chevron {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: gray;
                font-size: 1rem;
                padding: 0.3rem;
                margin-left: auto;
                cursor: pointer;
                z-index: 1;

                i {
                    pointer-events: none;
                }
            }
        }

        .filter-dropdown {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 0.5rem;
            width: 100%;
            padding: 0.5rem 0;
            background-color: #f9f9f9;
            border-bottom: 1px solid #ddd;

            .nos-articles__filter {
                padding: 0.5rem 1rem;
                text-align: left;
                background: none;
                border: none;
                width: 100%;
                cursor: pointer;
                transition: background-color 0.3s ease, color 0.3s ease;

                &:hover {
                    background-color: #f0f0f0;
                }

                &.active {
                    color: $color-1;
                    font-family: $font-2;
                }
            }
        }

        .nos-articles__content {
            flex-direction: column;
            width: 100%;
            margin-left: 0%;
            
            .nos-articles__main {
                padding: 10px;

                .nos-articles__grid {
                    grid-template-columns: 1fr; 
                    gap: 20px;
                }
                .jps-metaview-wrapper {
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    max-width: 350px;
                    margin-left: 3%;
                    margin-bottom: 10%;
                    
                    .jps-metaview {
                        width: 1200px;
                    }
                }
            }
        }
    }
}
