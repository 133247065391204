@font-face {
  font-family: "font-1";
  src: url(../assets/fonts/ProximaNova-Regular.ttf);
}

@font-face {
  font-family: "font-2";
  src: url(../assets/fonts/ProximaNova-Bold.ttf);
}

@font-face {
  font-family: "font-3";
  src: url(../assets/fonts/ProximaNova-Light.ttf);
}

@font-face {
  font-family: "font-4";
  src: url(../assets/fonts/ProximaNova-Semibold.ttf);
}

@font-face {
  font-family: "font-5";
  src: url(../assets/fonts/ProximaNova-Thin.ttf);
}

$font-1: "font-1", sans-serif;
$font-2: "font-2", sans-serif;
$font-3: "font-3", sans-serif;
$font-4: "font-4", sans-serif;
$font-5: "font-5", sans-serif;

  $color-1: #121E6E;
  $color-2: #ffffff;
  $color-3: #35428e;
  $color-4: #007bff;
  $color-5: #16B5B5;
  
  @mixin verticalCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: $font-1;
  }

  li {
    list-style-type: none;
  }
  
  body {
    background-color: #fcfdff;
  }
  