.contact-form-container {
    width: 450px;
    height: 700px;
    background-color: $color-1;
    padding: 25px 40px;
    box-sizing: border-box;
    color: white;
    border-radius: 8px;

    h2 {
        text-align: left;
        font-size: 1.5rem;
        margin-bottom: 5%;
    }

    .form-group {
        margin-bottom: 13px;

        label {
            display: block;
            margin-bottom: 8px;
            font-size: 0.7rem;
        }

        input,
        textarea {
            color: white;
            width: 100%;
            padding: 10px 20px;
            box-sizing: border-box;
            border: none;
            border-radius: 8px;
            background-color: rgba(255, 255, 255, 0);
            border: 1px solid #ddd;
        }

        input::placeholder,
        textarea::placeholder {
            color: rgba(255, 255, 255, 0.784);
            opacity: 1;
        }

        textarea {
            resize: vertical;
            height: 90px;
        }
    }

    .form-needs {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        .column {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            margin-right: 15%;

            .radio-checkbox {
                display: inline-flex;
                align-items: center;
                font-size: 0.8rem;
                text-align: left;
                margin-bottom: 4px;
                cursor: pointer;
                height: 20px;
                white-space: nowrap;
            }

            .radio-checkbox input[type="checkbox"] {
                display: none;
                margin-right: 8px;
            }

            .custom-radio {
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: white;
                display: inline-block;
                position: relative;
                margin-right: 10px;
                box-sizing: content-box;
                padding: 3px;
                border: 2px solid transparent;
                transition: border-color 0.2s;
            }

            .custom-radio::after {
                content: '';
                height: 8px;
                width: 8px;
                background-color: white;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: opacity 0.2s, background-color 0.2s;
            }

            .radio-checkbox input[type="checkbox"]:checked+.custom-radio {
                border-color: #0000FF;
            }

            .radio-checkbox input[type="checkbox"]:checked+.custom-radio::after {
                opacity: 1;
                background-color: #0000FF;
            }
        }
    }

    .form-disclaimer {
        font-size: 0.55rem;
        margin-bottom: 35px;
    }

    button {
        display: block;
        width: 50%;
        padding: 18px 7px;
        background-color: $color-5;
        border: none;
        border-radius: 8px;
        color: white;
        cursor: pointer;
        font-size: 0.7rem;
        text-align: center;
        margin: auto;
        letter-spacing: 0.05em;
        transition: all 0.3s ease;
    }

    button:hover {
        background-color: darken($color-5, 10%);
    }

    .popup {
        display: flex;
        flex-direction: column;
        background-color: $color-1;
        color: white;
        padding: 2% 5%;
        position: absolute;
        top: 100%;
        left: 50%;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        max-width: 510px;
        height: 100%;
        max-height: 800px;
        z-index: 1000;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
    }

    .popup-header {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        position: relative;
    }

    .popup-close-form {
        font-size: 1.5em;
        color: white;
        position: absolute;
        margin-top: 1%;
        top: 20%;
        right: 0%;
        cursor: pointer;
    }

    .popup-content {
        margin: 0 auto;
        padding: 2%;
        line-height: 1.5;
        color: white;
        background-color: transparent;
        text-align: center;

        p {
            font-size: 0.9em;
            color: white;
            margin-top: 33%;
            padding: 20px;
            border-radius: 8px;
        }

        a {
            color: white;
            text-decoration: underline;
        }
    }

    .PopUpLogoPicture {
        width: 90%;
        margin: 20% auto;
        display: block;
    }
}

@media (min-width: 1601px) {
    .contact-form-container {
        width: 500px;
        height: 750px;
        padding: 30px 60px;
    }
}

@media (max-width: 1600px) {
    .contact-form-container {
        width: 480px;
        height: 720px;
        padding: 30px 50px;
    }
}

@media (max-width: 1367px) {
    .contact-form-container {
        width: 450px;
        height: 700px;
        padding: 30px 50px;
    }
}

@media (max-width: 1200px) {
    .contact-form-container {
        width: 440px;
        height: 680px;
        padding: 25px 45px;
    }
}

@media (max-width: 1115px) {
    .contact-form-container {
        width: 350px;
        height: 590px;
        padding: 25px 30px;

        h2 {
            font-size: 1.3rem;
            margin-bottom: 5%;
        }

        .form-group {
            label {
                font-size: 0.7rem;
            }

            input,
            textarea {
                padding: 5px 20px;
                border: 1px solid #ddd;
            }

            textarea {
                height: 70px;
                margin-bottom: -8px;
            }
        }

        .form-needs {
            .column {
                margin-right: 10%;

                label {
                    font-size: 0.7rem;
                    height: 16px;
                }
            }
        }

        .form-disclaimer {
            font-size: 0.5rem;
            margin-bottom: 20px;
        }

        button {
            width: 100%;
            padding: 13px 7px;
            font-size: 0.7rem;
        }

        .popup-close-form {
            font-size: 1.5em;
        }

        .popup-content {
            p {
                font-size: 0.8em;
                color: white;
                margin-top: 33%;
                padding: 20px;
                border-radius: 8px;
            }
        }

        .PopUpLogoPicture {
            width: 90%;
            margin: 20% auto;
            display: block;
        }
    }
}

@media (max-width: 767px) {
    .contact-form-container {
        height: 620px;

        .popup-close-form {
            font-size: 1.3em;
        }

        .popup-content {
            p {
                font-size: 0.7em;
                color: white;
                margin-top: 40%;
                padding: 20px;
                border-radius: 8px;
            }
        }
    }
}