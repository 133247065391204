.article-blog-descriptif {
    margin: 0 auto;

    .bandeau-media {
        position: relative;
        width: 100vw;
        margin: 0;

        .img-bandeau {
            width: 100%;
            height: 100%;
            max-height: 550px;
            display: block;
            object-fit: cover;
        }

        .bandeau-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: white;
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
            z-index: 1;

            h3 {
                font-size: 1.7rem;
                margin-bottom: 2%;
                color: white;
            }

            h1 {
                font-size: 3rem;
                color: white;
                text-transform: uppercase;
                margin: 0% 16%;
                font-family: $font-4;
                letter-spacing: 0.05em;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            z-index: 0;
        }
    }

    .content-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 20px;
        margin: 0;

        main {
            flex-grow: 3;
            width: calc(40% - 1px);
            margin-right: 0;
            padding-right: 2px;
            margin-left: 40px;

            .bloc-texte-un,
            .bloc-texte-deux,
            .bloc-texte-trois {
                width: 100%;
                max-width: 80%;
                margin: 50px 50px;
            }

            .title-h1 {
                font-size: 2rem;
                margin-bottom: 20px;
                color: $color-3;
                font-family: $font-3;
                text-transform: uppercase;
                letter-spacing: 0.05em;
            }

            .title-h2 {
                font-size: 1.8rem;
                margin-bottom: 15px;
                color: $color-3;
                font-family: $font-3;
            }

            .title-h3 {
                font-size: 1.6rem;
                margin-bottom: 15px;
                color: $color-3;
                font-family: $font-4;
            }

            .title-h4 {
                font-size: 1.4rem;
                margin-bottom: 10px;
                color: $color-3;
                font-family: $font-4;
            }

            .title-h5 {
                font-size: 1.2rem;
                margin-bottom: 10px;
                color: $color-3;
                font-family: $font-4;
            }

            .paragraph {
                font-size: 1.1rem;
                margin-bottom: 15px;
                color: black;
                white-space: pre-line;
            }

            .list {
                list-style: none;
                padding: 0;
                margin-bottom: 15px;

                .list-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    font-size: 1.1rem;

                    .bullet {
                        margin-right: 5px;
                        font-size: 1.5rem;
                        color: $color-1;
                    }
                }
            }

            .img-media {
                width: 80%;
                margin-left: 50px;
                max-width: 100%;
                height: auto;
                display: block;
            }
        }
    }
}

@media (max-width: 768px) {
.article-blog-descriptif {
        margin: 0 auto;

        .bandeau-media {
            position: relative;
            width: 100vw;
            margin: 0;

            .img-bandeau {
                width: 100%;
                height: 100%;
                max-height: 250px;
                display: block;
                object-fit: cover;
            }

            .bandeau-content {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                color: white;
                width: 100%;
                padding: 0 20px;
                box-sizing: border-box;
                z-index: 1;

                h3 {
                    font-size: 1rem;
                    margin-bottom: 2%;
                    color: white;
                }

                h1 {
                    font-size: 1.3rem;
                    color: white;
                    text-transform: uppercase;
                    margin: 0% 10%;
                    font-family: $font-4;
                    letter-spacing: 0.05em;
                }
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                z-index: 0;
            }
        }
    .content-container {
        display: block;
        padding-top: 10px;
        margin: 0;

        main {
            width: 100%;
            margin-left: 0%;

            .bloc-texte-un,
            .bloc-texte-deux,
            .bloc-texte-trois {
                width: 100%;
                margin-left: 7%;
            }

            .title-h1 {
                font-size: 1.6rem;
                margin-bottom: 15px;
            }

            .title-h2 {
                font-size: 1.5rem;
                margin-bottom: 10px;
            }

            .title-h3 {
                font-size: 1.3rem;
                margin-bottom: 10px;
            }

            .title-h4 {
                font-size: 1.2rem;
                margin-bottom: 8px;
            }

            .title-h5 {
                font-size: 1.1rem;
                margin-bottom: 8px;
            }

            .paragraph {
                font-size: 1rem;
                margin-bottom: 10px;
            }

            .list {
                margin-bottom: 10px;

                .list-item {
                    margin-bottom: 8px;
                    font-size: 0.9rem;

                    .icon {
                        margin-right: 10px;
                        font-size: 0.9rem;
                    }
                }
            }

            .img-media {
                width: 100%;
                margin: 0;
                max-width: 100%;
                height: auto;
                display: block;
            }
        }
    }
}
}