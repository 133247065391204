.bandeau-un {
    height: 100%;

    .bandeau-descriptif-un {

        .text-section {
            width: 120%;

            .introduction {
                font-size: 0.9rem;
                margin-bottom: 1.5%;
            }

            .titre {
                font-size: 1.6em;
                font-family: $font-4;
            }

            p {
                font-size: 1rem;
                margin-bottom: 3%;
            }

            .descriptif {
                width: 90%;
                margin-bottom: 5%;
            }

            .liste {
                font-size: 1rem;

                li {
                    margin-bottom: 1%;
                }
            }

            .buttons {

                .btn-primary,
                .btn-secondary {
                    font-size: 0.8rem;
                }

                .btn-primary {
                    background-color: $color-1;
                }
            }
        }
    }
}

.section-info {
    text-align: center;
    margin-top: 5%;

    .titre-template-section {
        margin-top: 4%;
        font-size: 24px;
        color: $color-1;
        text-transform: uppercase;
        font-family: $font-4;
        letter-spacing: 0.05em;
    }

    .descriptif-template-section {
        font-size: 1.05rem;
        line-height: 150%;
        margin: 2% 4.5%;
        width: 88%;
        text-align: center;
    }

    .article-card {
        display: flex;
        flex-direction: row;
        margin-bottom: 4%;
    }
}

@media (max-width: 767px) {
    .bandeau-un {
        height: 100%;

        .bandeau-descriptif-un {
            height: 930px;

            .text-section {
                top: 30%;
                width: 100%;

                .introduction {
                    font-size: 0.8rem;
                    margin-bottom: 1.5%;
                }

                .titre {
                    font-size: 1.5em;
                    color: white;
                }

                p {
                    font-size: .9rem;
                    margin-bottom: 5%;
                    width: 100%;
                }

                .descriptif {
                    width: 90%;
                    margin-bottom: 5%;
                }

                .liste {
                    font-size: 1rem;

                    li {
                        margin-bottom: 2%;
                    }
                }

                .buttons {

                    .btn-primary,
                    .btn-secondary {
                        font-size: 0.8rem;
                    }

                    .btn-primary {
                        background-color: $color-5;
                    }
                }
            }
        }
    }

    .section-info {
        background-color: #f8f9f9;
        flex-direction: column;
        margin: 0;
        padding: 6%;
        padding-top: 1%;
        position: relative;
        border-radius: 20px 20px 0 0;
        margin-top: -20px;
        z-index: 1;

        .titre-template-section {
            margin-top: 10%;
            font-size: 24px;
            color: $color-1;
            text-transform: uppercase;
            font-family: $font-4;
            letter-spacing: 0.05em;
            text-align: left;
            margin-left: 2%;
        }

        .descriptif-template-section {
            font-size: 1rem;
            line-height: 150%;
            margin: 2% 2%;
            width: 88%;
            text-align: left;
        }
        .article-card {
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-bottom: 1%;
        }
    }
}