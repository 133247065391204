.logos-partenaires-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 3.5%;
}

.logos-partenaires {
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  overflow: hidden;
  margin: 0 4%;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  height: 180px;
  position: relative;
  animation: scroll 60s linear infinite;
  width: calc(387.5%);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.logos-partenaires:hover {
  animation-play-state: paused;
}

.logo {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin: 0 20px;
  flex-shrink: 0;
  filter: grayscale(100%) brightness(100%);
  transition: filter 0.3s ease;
}

.logo:hover {
  filter: grayscale(0%) brightness(100%);
}

@media (max-width: 1200px) {
  .logos-partenaires {
    height: 140px;
  }

  .logo {
    max-height: 45px;
  }
}

@media (max-width: 915px) {
  .logos-partenaires {
    height: 120px;
  }

  .logo {
    max-height: 40px;
  }
}

@media (max-width: 767px) {
  .logos-partenaires {
    height: 100px;
  }

  .logo {
    margin: 0 15px;
  }
}
