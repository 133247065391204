.faq-item {
    margin: 3%;

    .faq-container {
        display: flex;
        flex-direction: row;
    }

    .filters {
        width: 25%;
        padding: 20px;
        margin-top: 5%;
        display: block;

        ul {
            list-style: none;
            padding: 0;

            li {
                padding: 10px;
                cursor: pointer;
                transition: color 0.3s ease;
                font-size: 1.1em;

                &:hover {
                    color: $color-5;
                }

                &.active {
                    font-family: $font-2;
                    color: $color-1;
                    font-weight: bold;
                }
            }
        }

        .contact-link {
            color: $color-3;
            text-decoration: underline;
            display: block;
            margin-top: 20px;
            transition: color 0.3s ease;
            margin-left: 4%;
            font-size: 1.1em;

            &:hover {
                color: $color-5;
            }
        }

        @media (max-width: 768px) {
            width: 93%;
            padding: 0;
            margin: 7% 3%;
            margin-bottom: 10%;
            border-right: none;
            display: none;
        }
    }

    .content {
        width: 75%;
        padding: 20px;
        margin: 0 auto;

        h1 {
            margin-bottom: 20px;
            font-size: 2em;
            text-align: flex-start;
        }

        .question {
            border-bottom: 1px solid #ddd;
            border-top: 1px solid #ddd;
            padding: 7px 0;
            font-size: 1.1em;

            .question-header {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                padding: 10px 0;
                transition: background-color 0.3s ease;

                .question-selected {
                    color: $color-1;
                }

                h4 {
                    margin: 0;
                    transition: color 0.3s ease;

                    &:hover {
                        color: $color-5;
                    }
                }

                .chevron {
                    transition: transform 0.3s ease;
                }

                &.expanded {
                    .chevron {
                        transform: rotate(180deg);
                    }
                }
            }

            .answer {
                padding: 10px 0;
                display: block;
            }
        }
    }
}

@media (max-width: 768px) {
    .faq-item {
        position: relative;
        border-radius: 20px 20px 0 0;
        margin-top: -40px;
        background-color: white;
        z-index: 2;
        margin-left: 0%;
        margin-right: 0%;

        .faq-container {
            flex-direction: column;
        }

        .filters {
            display: block;
        }

        .filter-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0px;
            cursor: pointer;
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            border-top: 1px solid #ddd;
            margin: 3%;
            margin-top: 10%;

            h4 {
                margin: 0;
                flex-grow: 1;
                text-align: left;
                font-family: $font-2;
                color: $color-1;
            }

            .chevron {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: gray;
                font-size: 1rem;
                padding: 0.3rem;
                margin-left: auto;
                cursor: pointer;
                z-index: 1;
            }
        }

        .filter-dropdown {
            display: none;
            padding: 10px 20px;
            background-color: #fff;
            border-bottom: 1px solid #ddd;

            ul {
                list-style: none;
                padding: 0;

                li {
                    padding: 7px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                    &.active {
                        font-family: $font-2;
                        color: $color-1;
                    }

                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
            }
        }

        .filters.expanded .filter-dropdown {
            display: block;
        }

        .content {
            width: 100%;

            h1 {
                font-size: 1.5em;
                text-align: center;
            }

            .question {
                border-bottom: 1px solid #ddd;
                border-top: 1px solid #ddd;
                padding: 7px 0;

                .question-header {
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    padding: 10px 0;
                    transition: background-color 0.3s ease;

                    .question-selected {
                        color: $color-1;
                    }

                    h4 {
                        font-size: 1em;
                        transition: font-size 0.3s ease, font-weight 0.3s ease;

                        &:hover {
                            font-weight: bold;
                        }
                    }

                    .chevron {
                        transition: transform 0.3s ease;
                    }

                    &.expanded {
                        .chevron {
                            transform: rotate(180deg);
                        }
                    }
                }

                .answer {
                    font-size: 1em;
                    padding: 10px 0;
                    display: block;
                }
            }
        }

        .contact-link {
            display: block;
        }
    }
}