.contact-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .contact-page {
    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden;

    .text-section {
      flex: 1;
      position: absolute;
      top: 6%;
      left: 4%;
      color: black;
      z-index: 10;
      max-width: 50%;
      padding: 20px;
      border-radius: 10px;

      h1 {
        font-size: 1.4em;
        margin-bottom: 20px;
        font-family: $font-4;
      }

      p {
        margin-bottom: 20px;
        line-height: 1.5;
        font-size: 1.1em;

        &:nth-of-type(3) {
          font-family: $font-4;
        }

        a {
          text-decoration: none;
          color: $color-1;

          &:hover {
            color: $color-5;
          }
        }
      }
    }

    .image-section {
      flex: 1;
      height: 90vh;
      background: linear-gradient(
          to left,
          transparent 1%,
          rgba(255, 255, 255, 1) 100%
        ),
        url("../../../public/assets/images/Meeting.webp");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 30%;

      .form-container {
        position: absolute;
        right: 6%;
        top: 3%;
        z-index: 15;
      }
      .contact-form-container {
        max-height: 75vh;
        max-width: 53vh;
        overflow-y: auto;
        padding: 25px 35px;

        h2 {
          font-size: 1.3rem;
        }

        .radio-checkbox {
          font-size: 0.75rem;
        }

        .custom-radio {
          height: 4px;
          width: 4px;
        }

        input {
          height: 35px;
          padding-top: 2%;
          font-size: 0.75em;
        }

        textarea {
          height: 70px;
          padding-top: 3.5%;
        }

        .form-group {
          margin-bottom: 7px;
        }

        button {
          width: 45%;
          padding: 13px 7px;
          font-size: 0.7rem;
        }

        .form-disclaimer {
          font-size: 0.55rem;
          margin-bottom: 20px;
        }

        .form-needs {
          margin-bottom: 3%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .contact-page-wrapper {
    .contact-page {
      flex-direction: column;

      .text-section {
        position: relative;
        max-width: 100%;
        margin-bottom: 20px;
        padding: 20px 10px;
        padding-right: 7%;
        background: none;

        h1 {
          font-size: 1.2em;
          margin-bottom: 15px;
          font-family: $font-2;
          width: 60%;
        }

        p {
          margin-bottom: 15px;
          line-height: 1.5;

          &:nth-of-type(3) {
            font-family: $font-2;
            font-weight: bold;
          }

          a {
            text-decoration: none;
            color: $color-3;
          }
        }
      }

      .image-section {
        margin-left: 8%;
        background: none;

        .form-container {
          position: relative;
          padding: 0;
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          margin: 0 auto;
          max-width: 100%;

          .contact-form-container {
            height: 640px;
          }
        }
      }
    }
  }
}
