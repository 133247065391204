.template-sous-section {
    position: relative;
    width: 100%;
    overflow: hidden;

    .template-sous-section__bandeau {
        position: relative;
        width: 100%;

        .bandeau-media__content {
            position: relative;
            width: 100%;
            height: 65vh;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                z-index: 1;
            }
        }

        .bandeau-media__media {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 0;
        }

        .template-sous-section__overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 2;
            width: 60%;
            padding: 0 20px;
            color: white;

            .template-sous-section__title {
                font-size: 1.2rem;
                margin: 0;
                text-transform: uppercase;
                margin-bottom: 3%;
                letter-spacing: 0.05em;
            }

            .template-sous-section__introduction {
                font-size: 2.4rem;
                margin: 0;
                margin-top: 10px;
                font-family: $font-4;
            }
        }
    }

    .template-sous-section__content {
        display: flex;
        justify-content: flex-start;
        padding: 20px;
        box-sizing: border-box;

        .template-sous-section__form-container {
            position: relative;
            max-width: 400px;
        }

        .form-wrapper {
            position: relative;     
            
            &.form-fixed {
                position: fixed;
                top: 200px;
                max-width: 400px;
                width: 100%;
                z-index: 100;
            }

            &.form-stopped {
                position: absolute;
                bottom: 0;
                top: auto;
                width: 100%;
                max-width: 400px;
            }

            .contact-form-container {
                max-height: 74vh;
                max-width: 50vh;
                overflow-y: auto;
                padding: 25px 30px;

                h2 {
                    font-size: 1.3rem;
                }

                .radio-checkbox {
                    font-size: 0.75rem;
                }

                .custom-radio {
                    height: 4px;
                    width: 4px;
                }

                input {
                    height: 35px;
                    padding-top: 2%;
                    font-size: .75em;
                }

                textarea {
                    height: 70px;
                    padding-top: 3.5%;
                }

                .form-group {
                    margin-bottom: 7px;
                }
    
                button {
                    width: 45%;
                    padding: 13px 7px;
                    font-size: 0.7rem;
                }

                .form-disclaimer {
                    font-size: 0.55rem;
                    margin-bottom: 20px;
                }

                .form-needs {
                    margin-bottom: 3%;
                }
            }
        }
    }

    .section-articles {
        padding: 20px;

        h4 {
            font-size: 24px;
            color: $color-1;
            text-transform: uppercase;
            font-family: $font-4;
            text-align: center;
            letter-spacing: 0.05em;
            margin-top: 3%;
        }
    }

    .faq-item-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0px 20px;
        box-sizing: border-box;

        .faq-item {
            max-width: 1800px;
            width: 100%;
            box-sizing: border-box;
        }
    }

    .floating-buttons {
        bottom: 20px;
        right: 20px;
        z-index: 1001;
    }
}

@media (max-width: 768px) {
.template-sous-section {
        width: 100%;
        overflow: hidden;

        .template-sous-section__bandeau {
            position: relative;
            width: 100%;

            .bandeau-media__content {
                // position: relative;
                width: 100%;
                height: 85vh;
                overflow: hidden;

                &::after {
                    background: rgba(0, 0, 0, 0.255);
                }
            }

            .bandeau-media__media {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
                z-index: 0;
            }

            .template-sous-section__overlay {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                z-index: 2;
                width: 100%;
                padding: 0 20px;

                .template-sous-section__title {
                    font-size:.8rem;
                }

                .template-sous-section__introduction {
                    font-size: 1.7rem;
                    width: 100%;
                }
            }
        }

        .template-sous-section__content {
            display: flex;
            justify-content: flex-start;
            box-sizing: border-box;
            padding: 0px 0px;

            .template-sous-section__left {
                    width: 100%;
                    box-sizing: border-box;
                    z-index: 1;
                    text-align: left;
                    margin-top: 150%;
                }

                .template-sous-section__form-container {
                    position: absolute;
                    top: 460px;
                    padding-left: 3%;
                }

                .form-wrapper {
                    position: relative;
                    z-index: 3;

                    &.form-fixed,
                    &.form-stopped {
                        position: relative;
                        top: auto;
                        bottom: auto;
                        width: 100%;
                        max-width: none;
                    }
                }
            }

        .section-articles {
            padding: 0px;

            h4 {
                font-size: 24px;
                color: $color-1;
                text-transform: uppercase;
                font-family: $font-4;
                text-align: center;
                letter-spacing: 0.05em;
            }

            .article-list {

        .article-card:nth-of-type(1) {                    
                        padding-left: 4%;
                }
            }
        }

        .faq-item-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            box-sizing: border-box;

            .faq-item {
                width: 100%;
                box-sizing: border-box;
            }
        }

        .floating-buttons {
            bottom: 20px;
            right: 20px;
            z-index: 1001;
        }
    }
}



