.header {
  width: 100%;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1000;

  .headerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: 1%;
    padding-right: 3.7%;
    position: relative;

    .header-logo {
      width: 20%;
      max-width: 1200px;
      padding: 0 2px;
      position: relative;

      .HeaderLogoPicture {
        width: 100%;
        margin-left: 6%;
        z-index: 1001;
      }
    }

    .headerList {
      display: flex;
      align-items: center;
      gap: 40px;
      font-size: 0.85em;

      a {
        text-decoration: none;
        color: black;
        transition: color 0.3s ease;

        &:hover {
          color: $color-5;
        }
      }

      li {
        list-style: none;
        color: black;

        span {
          font-family: $font-2;
        }
      }
    }
  }

  .mobile-menu-toggle {
    background-color: $color-1;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1001;

    .menu-toggle-icon {
      font-size: 1.1rem;
      color: white;
    }
  }
}
@media (max-width: 768px) {
  .header {
    .headerSection {
      width: 100%;
      height: 70px;

      .header-logo {
        width: 60%;
        max-width: 1200px;
        padding: 0 2px;
        position: relative;

        .HeaderLogoPicture {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 0%;
        }
      }
    }

    .headerList {
      display: none;
    }

    .mobile-menu-toggle {
      display: flex;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
    }
  }

  @media (min-width: 769px) {
    .mobile-menu-toggle {
      display: none;
    }
  }
}