.article-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding-top: .5%;
    box-sizing: border-box;
}

.article-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: calc(23.2% - 20px);
    text-decoration: none;
    margin-top: 3%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    border: solid 1px #00000020;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

        .article-content {
            transition: color 0.3s ease;

            h3 {
                color: $color-5;
                transition: color 0.3s ease;
            }
        }
    }

    a {
        text-decoration: none;
    }
}

.article-image {
    width: 100%;
    height: 265px;
    object-fit: cover;
    object-position: center;
}

.article-content {
    padding: 4% .8%;
    color: black;
    text-align: left;

    h3 {
        font-size: 1.2em;
        margin: 3% 6%;
        color: $color-3;
        font-family: $font-4;
    }

    .p-content {
        font-size: .9rem;
        margin: 3% 6%;
        font-family: $font-4;
    }

    .p-intro {
        font-size: .9rem;
        margin: 4% 6%;
        font-family: $font-1;
    }
}

@media (max-width: 768px) {
    .section-articles {
        padding: 0;

        h4 {
            font-size: 20px;
            text-align: center;
            margin-bottom: 15px;
        }

        .article-list {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: flex-start;
            gap: 10px;
            overflow-x: auto;
            scroll-snap-type: x mandatory;
            padding-bottom: 10px;

            &::-webkit-scrollbar {
                display: none;
            }

            .article-card {
                flex: 0 0 75vw;
                scroll-snap-align: start;
                margin-bottom: 10px;
            }

            .article-card:nth-child(1) {
                margin-left: 4%;
            }

            .article-card:nth-last-child(1) {
                margin-right: 4%;
            }
        }
    }

    .article-content {
        padding: 3% .8%;
        color: black;
        text-align: left;

        h3 {
            font-size: 1.2em;
        }

        .p-content {
            font-size: .9rem;
        }

        .p-intro {
            font-size: .9rem;
        }
    }
}