
.question-projet {
    display: flex;
    align-items: center;
    width: 74%;
    margin: 50px auto;

    .question-projet__image-container {
        flex: 1;
        position: relative;
        height: 350px;
        overflow: hidden;
        border-radius: 8px;
    }

    .question-projet__image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, transparent 50%, rgba(255, 255, 255, 1) 100%);
            z-index: 1;
        }
    }

    .question-projet__text-container {
        flex: 1;
        padding: 20px;
        z-index: 1;

        .question-projet__title {
            font-size: 1.6rem;
            margin-bottom: 35px;
            color: $color-3;
            width: 98%;
            font-family: $font-4;
        }

        .question-projet__description {
            font-size: 1rem;
            margin-bottom: 40px;
            text-align: justify;
            line-height: 130%;
            width: 90%;
        }

        .question-projet__button {
            text-decoration: none;
            width: 40%;
            height: 50px;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            font-size: 0.7em;
            background-color: $color-1;
            color: #fff;
            text-transform: uppercase;
            margin-top: 1%;
            letter-spacing: 0.05em;
            transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

            &:hover {
                text-decoration: none;
                background-color: $color-5;                
                padding: 0;
            }
        }
    }
}

@media (max-width: 1200px) {
    .question-projet {

        .question-projet__text-container {

            .question-projet__button {
                width: 50%;
                height: 50px;                    
                font-size: 0.7em;
            }
        }
    }
}

@media (max-width: 1000px) {
    .question-projet {

        .question-projet__text-container {

            .question-projet__button {
                width: 60%;
                height: 50px;
                font-size: 0.7em;
            }
        }
    }
}

@media (max-width: 768px) {
    .question-projet {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        margin: 20px auto;

        .question-projet__image-container {
            flex: 0 0 auto;
            width: 100%;
            height: 220px;
            position: relative;
            overflow: hidden;
            border-radius: 7px 7px 0px 0px;
            margin-bottom: 0px;
        }

        .question-projet__image {
            width: 100%;
            background-size: cover;
            background-position: center;
            position: relative;
    
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, transparent 50%, rgba(255, 255, 255, 1) 97%);
                z-index: 1;
            }
        }

        .question-projet__text-container {
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            z-index: 1;
            text-align: left;

            .question-projet__title {
                font-size: 1.4rem;
                margin-bottom: 20px;
                color: $color-3;
                width: 100%;
                font-family: $font-4;
            }

            .question-projet__description {
                font-size: 1rem;
                margin-bottom: 20px;
                text-align: justify;
                line-height: 1.4;
                width: 100%;
            }

            a {
                text-decoration: none;
                
                .question-projet__button {
                    display: block;
                    width: 250px;
                    height: 54px;
                    padding: 2% 3%;
                    border: none;
                    border-radius: 8px;
                    cursor: pointer;
                    font-size: 0.9em;
                    background-color: $color-1;
                    color: #fff;
                    text-transform: uppercase;
                    margin: 20px auto 0;
                    text-align: center;
                    letter-spacing: 0.05em;
                    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
                    
                    &:hover {
                        text-decoration: none;
                        background-color: $color-5;                
                    }
                }
            }
        }
    }
}