.qui-sommes-nous-page {
  .bandeau-descriptif {
    &.index-1 {
      padding: 3% 3.5%;

      .image-section {
        margin-right: 1.9em;
        position: relative;
        width: 100%;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      .text-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-right: -5%;

        .titre {
          font-size: 1.4em;
          margin-bottom: 8px;
          font-family: $font-4;
          color: $color-1;
        }

        p {
          font-size: 1em;
          color: black;
          margin-top: 0.6em;
          line-height: 1.2em;
          padding-right: 3%;
          width: 95%;
        }

        .liste {
          list-style-type: none;
          padding: 0;
          color: $color-1;
          font-size: 1em;
          margin-top: 3%;
          margin-right: 5%;

          li {
            line-height: 1em;
            display: flex;
            align-items: center;
            margin-bottom: 1.3%;

            .fas {
              margin-right: 0.5rem;
            }
          }
        }

        .btn-primary {
          width: 40%;
          height: 52px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 0.85em;
          font-family: $font-3;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.3s ease;
          letter-spacing: 0.05em;
          margin-top: 5%;
          padding: 0% 4%;
          background-color: $color-1;
          color: white;
          margin-right: 4%;

          &:hover {
            text-decoration: none;
            background-color: #2a9ba0;
          }
        }
      }
    }

    &.index-2 {
      padding: 3% 3.5%;

      .image-section {
        margin-right: 0.5%;
        position: relative;
        width: 100%;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      .text-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-right: -5%;

        .titre {
          font-size: 1.4em;
          margin-bottom: 8px;
          font-family: $font-4;
          color: $color-1;
        }

        p {
          font-size: 1em;
          color: black;
          margin-top: 0.6em;
          line-height: 1.2em;
          padding-right: 3%;
          width: 85%;
        }

        .liste {
          list-style-type: none;
          padding: 0;

          li {
            font-size: 0.9em;
            line-height: 0.95em;
            display: flex;
            align-items: center;
            color: black;
            margin-bottom: 1%;

            .fas {
              margin-right: 0.5rem;
              font-size: 0.9em;
            }
          }
        }

        .btn-primary {
          width: 40%;
          height: 52px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 0.85em;
          font-family: $font-3;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.3s ease;
          letter-spacing: 0.05em;
          margin-top: 5%;
          padding: 0% 4%;
          background-color: $color-1;
          color: white;
          margin-right: 4%;

          &:hover {
            text-decoration: none;
            background-color: #2a9ba0;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .qui-sommes-nous-page {
    .bandeau-descriptif {
      margin-top: -70px;

      &.index-1 {
        padding: 3% 3.5%;

        .image-section {
          margin: auto;
        }

        .text-section {
          width: 100%;
          margin: 3%;

          .btn-primary {
            margin: auto;
            margin-bottom: 25%;
            margin-top: 10%;
            width: 58%;
            height: 42px;
            font-size: 0.75em;
          }
        }
      }

      &.index-2 {
        padding: 3% 0%;

        .image-section {
          margin: auto;
        }

        .text-section {
          width: 100%;
          margin: 3%;
          padding-left: 7%;

          .btn-primary {
            margin: auto;
            margin-bottom: 0%;
            margin-top: 10%;
            width: 60%;
            height: 42px;
            font-size: 0.75em;
          }
        }
      }
    }

    .bandeau-descriptif-un {
      .text-section {
        height: 1000px;

        .buttons {
          .btn-primary {
            margin-top: 7%;
          }
        }
      }
    }
  }
}
