.floating-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    top: 40%;
    right: 12px;

    .btn-floating {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid transparent;
        border-radius: 5px;
        cursor: pointer;
        font-size: 2.3em;
        background-color: #fff;
        color: #000;
        opacity: 90%;
        transition: color 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

        &:hover,
        &:focus {
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        }

        &>* {
            position: relative;
            z-index: 2000;
        }
    }

    .btn-floating.btn-whatsapp {
        background-color: #fff;
        color: rgb(0, 172, 0);

        &:before {
            content: '';
            position: absolute;
            bottom: -100%;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #58cb70;
            transition: bottom 0.25s ease;
            z-index: 1;
        }

        &:hover:before,
        &:focus:before {
            bottom: 0;
        }

        &:hover,
        &:focus {
            color: #fff;
            border-color: #58cb70;
        }
    }

    .btn-floating.btn-devis {
        background-color: #fff;
        color: $color-1;
        font-size: 0.7em;
        line-height: 130%;

        &:before {
            content: '';
            position: absolute;
            bottom: -100%;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-1;
            transition: bottom 0.25s ease;
            z-index: 1;
        }

        p {
            font-family: $font-2;
        }

        p::after {
            content: '';
            display: block;
            width: 120%;
            height: .07em;
            background: linear-gradient(to right, transparent, $color-1, transparent);
            position: absolute;
            left: -10%;
            right: 0;
            top: 48%;
            transform: translateY(-50%);
            z-index: 1;
        }

        &:hover:before,
        &:focus:before {
            bottom: 0;
        }

        &:hover,
        &:focus {
            color: #fff;
            border-color: $color-1;
        }
    }
}

@media (max-width: 767px) {
    .floating-buttons {
        top: 14.5%;
        right: 6px;
        z-index: 999;

        .btn-floating {
            font-size: 2.3em;

            &:hover,
            &:focus {
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
            }
        }

        .btn-floating.btn-whatsapp {

            &:hover,
            &:focus {
                color: #fff;
                border-color: #58cb70;
            }
        }

        .btn-floating.btn-devis {
            font-size: 0.7em;
            line-height: 130%;

            p::after {
                height: .07em;
                background: linear-gradient(to right, transparent, $color-1, transparent);
            }

            &:hover,
            &:focus {
                color: #fff;
                border-color: $color-1;
            }
        }
    }
}