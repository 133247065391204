.bandeau-media {
    text-align: center;

    .bandeau-media__title {
        font-size: 24px;
        color: $color-1;
        text-transform: uppercase;
        font-family: $font-4;
        margin-bottom: 4%;
        letter-spacing: 0.05em;
    }

    .bandeau-media__content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .bandeau-media__content--left {
            justify-content: flex-start;
            margin-left: 6.5%;
            margin-right: 0%;
        }

        .bandeau-media__content--center {
            justify-content: center;
        }

        .bandeau-media__content--right {
            justify-content: flex-end;
        }
    }

    .bandeau-media__video-container {
        position: relative;
        width: 100%;
        max-width: 2000px;
        height: auto;

        .bandeau-media__media {
            max-width: 100%;
            width: 57%;
            height: auto;
        }

        .bandeau-media__play-button {
            width: 10%;
            height: 27%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 2px solid #fff;
            border-radius: 50%;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 10;
            transition: background-color 0.3s ease;

            i {
                color: #fff;
                font-size: 3em;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}

@media (min-width: 1601px) {
    .bandeau-media {
        .bandeau-media__title {
            font-size: 34px;
            margin-bottom: 3%;
        }

        .bandeau-media__play-button {
            width: 6.5%;
            height: 25%;
        }
    }
}

@media (max-width: 1600px) {
    .bandeau-media {
        .bandeau-media__play-button {
            width: 8%;
        }
    }
}

@media (max-width: 1200px) {
    .bandeau-media {
        .bandeau-media__title {
            font-size: 20px;
            margin-bottom: 3%;
        }

        .bandeau-media__play-button {
            width: 9%;
        }
    }
}

@media (max-width: 1115px) {
    .bandeau-media {
        .bandeau-media__title {
            font-size: 18px;
            margin-bottom: 3%;
        }

        .bandeau-media__play-button {
            width: 9%;
        }
    }
}

@media (max-width: 915px) {
    .bandeau-media {
        .bandeau-media__title {
            font-size: 17px;
            margin-bottom: 3%;
        }

        .bandeau-media__play-button {
            width: 9%;
        }
    }
}

@media (max-width: 767px) {
    .bandeau-media {
        margin-bottom: 6%;

        .bandeau-media__title {
            font-size: 18px;
            margin: 10% 3%;
        }

        .bandeau-media__content {
            width: 100%;

            .bandeau-media__content--left {
                margin-left: 0%;
            }

            .bandeau-media__video-container {
                .bandeau-media__media {
                    width: 100%;
                    max-width: none;
                }

                .bandeau-media__play-button {
                    width: 18%;
                    height: 29%;

                    i {
                        font-size: 2em;
                    }
                }
            }
        }
    }
}