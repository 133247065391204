
.simulateur {
    display: flex;
}

.aside {
    width: 25%;
    color: white;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;

    .aside-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: 150px;
        }

        .back-button {
            display: flex;
            align-items: center;
            background: none;
            border: none;
            color: white;
            cursor: pointer;
            transition: color 0.3s ease;
        
            &:hover {
                color: $color-5;
            }
            
            .arrow {
                font-size: 1.5rem;
                margin-right: 0.5rem;
            }
            
            .return {
                font-size: 1rem;
            }
        }
    }

    .aside-content {
        margin-top: 2rem;

        h2 {
            font-size: 1.2rem;
        }

        p {
            font-size: 0.9rem;
        }

        .traits {
            display: flex;
            gap: .2rem; 
            margin-top: 2rem;

            .trait {
                height: 1px;
                background-color: white;
                flex: 1; 
            }
        }
    }
}

.simulateur-content {
    flex: 1;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #f8faff;
}

.main {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin: auto;
    max-width: 800px;
    height: 100%;

    h1 {
        margin-bottom: 2rem;
        margin-top: 2rem;
        font-size: 2.5rem;
    }

    p {
        margin-bottom: 1rem;
        font-size: 1rem;
    }

    .rectangle-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        margin-bottom: 2rem;

        &.smaller-rectangles .rectangle {
            height: 80px;
        }

        .rectangle {
            background: white;
            padding: 1rem;
            border-radius: 3px;
            height: 120px;
            cursor: pointer;
            transition: background 0.3s, color 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #cccccc92;

            &:hover {
                background: $color-1;
                color: white;
            }
        }
    }

    .image-container {
        img {
            width: 100%;
        }
    }

    .info-box {
        padding: 1.5rem;
        border: 2px solid $color-1;
        border-radius: 8px;
        color: $color-1;
        height: 120px;

        p {
            font-size: .8em;
        }
    }

    .card-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        margin-top: 2rem;

        .card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            background: white;
            border: 1px solid #ddd;
            border-radius: 8px;
            transition: background 0.3s;
            height: 100%;

            &:hover {
                background-color: $color-1;
                color: $color-2;
            }

            img {
                width: 100%;
                height: auto;
            }

            p {
                margin: auto;
                padding: 18%;
                font-size: 1rem;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                height: auto;
            }
        }
    }

    .centered-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 600px;
        margin: auto;

        h1 {
            font-family: $font-4;
        }

        p {
            margin-bottom: 1rem;
        }

        .first-paragraph {
            font-size: 1.5rem;
        }

        .second-paragraph {
            font-size: 0.9rem;
        }

        .highlight {
            color: $color-1; 
            font-weight: bold;
        }

        .email-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;
            width: 100%;

            label {
                margin-bottom: 0.5rem;
                margin-left: 36%;
                width: 100%;
                font-size: .7em;
                font-family: $font-2;
            }

            input[type="email"] {
                padding: 1.5% 2%;
                width: 100%;
                max-width: 400px;
                border: 1px solid #ccc;
                border-radius: 8px;
                margin-bottom: 1rem;
                background-color: #f8faff;
                font-size: .8em;
            }

            input[type="submit"] {
                padding: 0.5rem 1rem;
                background-color: $color-5;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.3s;
                width: 100%;
                max-width: 180px;
                height: 37px;
                font-size: .7em;
                font-family: $font-5;
                transition: background-color 0.3s ease, color 0.3s ease, font-size 0.3s ease, font-weight 0.3s ease; // Ajout de transitions pour le hover
            
                &:hover {
                    text-decoration: none;
                    font-weight: bold;
                    font-size: .8rem;
                    background-color: #0097a7;
                }
            }
        }
    }
}

.final-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    padding: 1rem;
    box-sizing: border-box;

    .centered-content {
        margin: auto;
    }
}

.main-sec-quatre {
    display: flex;
    flex-direction: column;
    height: 92vh;
    position: relative;

    .rectangle-grid {
        flex: 1;
    }

    .info-box {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border: 2px solid $color-1;
        border-radius: 8px;
        color: $color-1;
        height: 100px;
        box-sizing: border-box;
        margin: 0 3%;
        display: flex;
        align-items: center;
        justify-content: center; 

        p {
            font-size: .8em;
            margin: 0; 
        }
    }
}

.popup {
    display: flex;
        flex-direction: column;
        background-color: $color-1;
        color: white;
        padding: 20px;
        position: absolute;
        top: 50%;
        left: 65%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 600px;
        height: auto;
        max-height: 100%;
        overflow-y: auto;
        z-index: 1000;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
}

.popup-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
}

.popup-close-form {
    font-size: 1.5em;
    color: white;
    position: absolute;
    top: -5px;
    left: 540px;
    cursor: pointer;
}

.popup-content {
    margin: 0 auto;
    padding: 2%;
    line-height: 1.5;
    color: white;
    background-color: transparent;
    text-align: center;

    p {
        font-size: 1em;
        color: white;
        margin-top: 5%;
        padding: 20px 40px;
        border-radius: 8px;
    }
    a {
        color: white;
        text-decoration: underline;
    }
}
        
.PopUpLogoPicture {
    width: 90%;
    margin: 2% auto;
    display: block;
}

@media (min-width: 1601px) {
    .simulateur {
        display: flex;
    }

    .aside {
        width: 25%;
        padding: 2rem; 
    }

    .simulateur-content {
        padding: 2rem;
    }

    .main {
        max-width: 800px;
    }
}

@media (max-width: 1600px) {
    .aside {
        width: 20%; 
    }

    .main {
        max-width: 750px;
    }
}

@media (max-width: 1200px) {
    .aside {
        width: 30%; 
    }

    .main {
        max-width: 700px;
    }

    .rectangle-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .card-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 915px) {
    .aside {
        width: 100%;
        margin-bottom: 1rem;
    }

    .simulateur {
        flex-direction: column;
    }

    .main {
        max-width: 100%;
        padding: 1rem;
    }

    .rectangle-grid,
    .card-grid {
        grid-template-columns: 1fr;
    }
        .popup {
            width: 90%;
            max-width: 500px;
        }
    
        .popup-header {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            position: relative;
        }
    
        .popup-close-form {
            font-size: 1.5em;
            color: white;
            position: absolute;
            top: -5px;
            left: 445px;
            cursor: pointer;
        }
    
        .popup-content {
            margin: 0 auto;
            padding: 2%;
            line-height: 1.5;
            color: white;
            background-color: transparent;
            text-align: center;
    
            p {
                font-size: .9em;
                color: white;
                margin-top: 5%;
                padding: 20px 40px;
                border-radius: 8px;
            }
    
            a {
                color: white;
                text-decoration: underline;
            }
        }
    
        .PopUpLogoPicture {
            width: 70%;
            margin: 2% auto;
            display: block;
        }
}

@media (max-width: 767px) {
    .simulateur {
        display: flex;
        flex-direction: column;
    }

    .simulateur-content {
        padding-top: 0rem;
    }

    .header-mobile {
        background-color: white;
        padding: 0rem;
        box-sizing: border-box;

        .logo {
            width: 100px;
            padding-left: 0%;
        }

        .back-button {
            background-color: #007bff;
            border-radius: 50%;
            padding: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .arrow {
                color: white;
                font-size: 1.5rem;
            }
        }
    }

    .main {
        padding: 1rem;
        background-color: #f8faff;
        text-align: left;

        h1 {
            font-size: 1.8rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1rem;
            margin-bottom: 1rem;
        }

        .rectangle-grid,
        .card-grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .popup {
        width: 90%;
        max-width: 500px;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .popup-close-form {
        left: auto;
        right: 10px;
    }

    .popup-content p {
        font-size: 0.9rem;
    }
}

.final-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50vh;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;

    .centered-content {
        margin: auto;

        .email-form label {
                margin-bottom: 0.5rem;
                margin-left: 0%;
                width: 100%;
                font-size: .7em;
                font-family: $font-2;
         }
    }
}