.cookies-bandeau {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $color-1;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4% 4%;
  z-index: 1000;

  &__text {
    font-size: 0.75em;
    flex: 1;
    margin-right: 2%;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  &__buttons {
    display: flex;
    gap: 10px;
    font-size: 1em;
  }

  &__button {
    background-color: #2ea3aa;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      background-color: darken($color-5, 10%);
    }
  }

  &__button:nth-child(2) {
    background-color: $color-1;
    border: 2px solid #fff;
    color: white;
    font-weight: 300;

    &:hover {
      background-color: darken($color-2, 10%);
      color: black;
    }
  }
}

@media (max-width: 767px) {
  .cookies-bandeau {
    padding: 3% 5%;
    display: inline-block;

    &__text {
      font-size: 0.7em;
      margin-bottom: 15px;
    }

    &__buttons {
      gap: 5px;
      font-size: 0.9em;
      justify-content: center;
    }

    &__button {
      padding: 6px 15px;
    }
  }
}
